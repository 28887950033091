import React from 'react';
import { LoadingProvider } from './components/LoadingContext';
import App from './App';

const AppWrapper = () => {
  return (
    <LoadingProvider>
      <App />
    </LoadingProvider>
  );
};

export default AppWrapper;
