import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../components/Breadcrumbs';
import ContactInformationPage from './ContactInformationPage';


const Banner = styled.div`
  background-color: #4caf50;
  padding: 5rem;
  text-align: center;
  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
  font-size: 1.5em;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
  font-size: 1.7em;
  font-weight: 600;
  background: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;


const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Vg = () => {
  return (
 <>
      <Banner>
        <BannerTitle>Visa Guidance</BannerTitle>
        <Underline />
      </Banner>
      <Breadcrumbs/>
      <Content>
      <Main>Our Visa Processes include:</Main>
        <ContentHeader>Step 1: Preliminary Visa Counseling 
</ContentHeader>
        <ContentParagraph>
        At the outset, we will provide a broad overview of the visa process. We will outline the different visa categories, discuss the requirements for each one, and help you determine which visa best aligns with your education and career goals.
        </ContentParagraph>
        <ContentHeader>Step 2: Document Review 
</ContentHeader>
        <ContentParagraph>
        Proper documentation is critical in the visa application process. Our team will review your documents meticulously to ensure everything is in order, including your passport, proof of acceptance at a foreign institution, financial documents, and any other paperwork pertinent to your specific visa category.
        </ContentParagraph>
        <ContentHeader>Step 3: Application Assistance</ContentHeader>
        <ContentParagraph>
        We understand that filling out visa applications can be a daunting task. Our team will guide you through the application process step-by-step, providing expert advice and support to ensure your application is correctly filled out and complete.
            </ContentParagraph>
        <ContentHeader>Step 4:  Preparation for Visa Interview</ContentHeader>
        <ContentParagraph>
        Visa interviews can be stressful, but preparation is key. We offer mock interview sessions where we go over potential questions, help you prepare responses, and provide feedback to improve your performance. We also advise on the proper etiquette and what to expect on the day of your interview.
        </ContentParagraph>
        <ContentHeader>Step 5: Tracking the Application Status</ContentHeader>
        <ContentParagraph>
        After you've submitted your application and completed your interview, we'll continue to track your application status. We'll keep you updated and promptly notify you of any additional steps you need to take.
        </ContentParagraph>
        <ContentHeader>Step 6: Post-Visa Guidance</ContentHeader>
        <ContentParagraph>
        Once you've obtained your visa, our support doesn't end. We provide guidance on travel, accommodation, and living in your new country. We also offer advice on compliance with the terms of your visa to avoid any legal issues.
        </ContentParagraph>
        <Conclusion>Visa applications can be complicated, but we're here to make it easier. Through every step of the process, we offer guidance, support, and expert advice to make your journey to studying abroad as smooth as possible. We understand that every student's situation is unique, so our services are tailored to meet your specific needs and circumstances. Don't hesitate to contact us for any of your visa guidance needs.</Conclusion>
      </Content>
      <Contact>Contact Us</Contact>
      <ContactInformationPage includeAddress={false}/>
      </>
  )
}

export default Vg
