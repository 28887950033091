import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './AppWrapper';


ReactDOM.createRoot(document.getElementById('root')).render(
<React.StrictMode>
<Router>
  <AppWrapper />
</Router>
</React.StrictMode>,
)
