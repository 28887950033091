import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTelegramPlane, FaInstagram, FaLinkedin } from 'react-icons/fa';
import logo1 from '../images/logo.png';
import logo2 from '../images/SNU.png'

const FooterContainer = styled.footer`
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1 ;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1180px) {
    font-size: 1rem;
    align-items: flex-start;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    align-items: flex-start;
  }
`;

const CompanyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const CompanyLogo = styled.img`
  height: 40px;
  width: auto;
`;

const Address = styled.div`
  text-align: center;
  color: #1466d9;

`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const SocialMediaIcon = styled.a`
  color: #969696;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: transparent;

`;



const SiteMap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  

  @media screen and (max-width: 1180px) {
    flex-direction: column;
 
  }

`;


const SiteMapSection = styled.div`
  text-align: center;
  margin: 0 2rem; 

  @media screen and (max-width: 1180px) {
    text-align: left; 
    margin: 0 1rem; 
  }
`;

const SiteMapTitle = styled.h3`
margin-top: 1rem;
  margin-bottom: 1rem;
  color: #1466d9;
`;

const SiteMapLink = styled(Link)`
  display: block;
  color: #969696;
  text-decoration: none;
  margin-bottom: 0.5rem;
  
  &:hover {
    color: #ccc;
  }
`;

const Copyright = styled.p`
  margin-top: 1rem;
  color: #696969;
  font-size: 0.8rem;
  font-weight: 700;
  @media screen and (max-width: 1180px) {
    font-size: 0.7rem;
  }
`;

const Divider = styled.hr`
  width: 80%;
  height: 1px;
  background-color: #ddd;
  border: none;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
`;



const Footer = () => {
  return (
    <FooterContainer>
         <CompanyContainer>
         <Company>
          <CompanyLogo src={logo1} alt="Company 1 Logo" />
          <Address>
          No.1106, 11th Floor, Pearl Condo, Block C, Bahan Township, Yangon
      </Address>
        </Company>
        <Company>
          <CompanyLogo src={logo2} alt="Company 2 Logo" />
          <Address>
          Ground Floor, Pearl Condo, Block C, Bahan Township, Yangon
      </Address>
        </Company>
      </CompanyContainer>
      <SocialMedia>
      <SocialMediaIcon
  href="https://www.facebook.com/SafenetEducation"
  target="_blank"
  rel="noopener noreferrer"
  className="facebook"
>
  <FaFacebook />
</SocialMediaIcon>
<SocialMediaIcon
  href="https://t.me/safeneteducation?fbclid=IwAR2ZsnVXrtfByDGJ8Ex8XS0HeLwtRd0p9OYRFgN1aSjJFG0HfYxGylkqql8"
  target="_blank"
  rel="noopener noreferrer"
  className="telegram"
>
  <FaTelegramPlane />
</SocialMediaIcon>
<SocialMediaIcon
  href="https://www.instagram.com/safenetedu_2023/"
  target="_blank"
  rel="noopener noreferrer"
  className="instagram"
>
  <FaInstagram />
</SocialMediaIcon>
<SocialMediaIcon
  href="https://www.linkedin.com/company/safenet-education-consultancy-services/"
  target="_blank"
  rel="noopener noreferrer"
  className="linkedin"
>
  <FaLinkedin />
</SocialMediaIcon>
</SocialMedia>
      <SiteMap>
        <SiteMapSection>
          <SiteMapTitle>Study Abroad</SiteMapTitle>
          <SiteMapLink to="/study-in-uk">Study in UK</SiteMapLink>
          <SiteMapLink to="/study-in-us">Study in US</SiteMapLink>
        </SiteMapSection>

        <SiteMapSection>
          <SiteMapTitle>Study Abroad</SiteMapTitle>
          <SiteMapLink to="/study-in-uk">Study in UK</SiteMapLink>
          <SiteMapLink to="/study-in-us">Study in US</SiteMapLink>
        </SiteMapSection>
        <SiteMapSection>
          <SiteMapTitle>Company</SiteMapTitle>
          <SiteMapLink to="/about">About</SiteMapLink>
          <SiteMapLink to="/careers">Office Tour</SiteMapLink>
          <SiteMapLink to="/portals">Portals</SiteMapLink>
          <SiteMapLink to="/contact">Contact</SiteMapLink>
        </SiteMapSection>
      </SiteMap>
      <Divider />
      <Copyright>© 2023 SafeNet Education. All Rights Reserved.</Copyright>
    </FooterContainer>
  );
};

export default Footer;
