import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../components/Breadcrumbs';
import ContactInformationPage from './ContactInformationPage';

const Banner = styled.div`
  background-color: #4caf50;;
  padding: 5rem;
  text-align: center;

  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.7em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Ap = () => {
  return (
 <>
      <Banner>
        <BannerTitle>Application Process</BannerTitle>
        <Underline />
      </Banner>
      <Breadcrumbs/>
      <Content>
      <Main>Our Application Processes include:</Main>
        <ContentHeader>Step 1: Initial Consultation</ContentHeader>
        <ContentParagraph>
        The first step is a comprehensive consultation where we get to understand your educational goals, interests, and potential challenges. This involves a detailed discussion about your academic history, career ambitions, and preferred study destinations. Our experts use this information to provide personalized guidance and advice.
        </ContentParagraph>
        <ContentHeader>Step 2: Course and University Selection</ContentHeader>
        <ContentParagraph>
        Based on your academic profile and preferences, we'll guide you through the process of course and university selection. Our extensive knowledge of global education opportunities ensures you have a range of quality choices. We'll provide you with detailed information on universities, courses, admission requirements, and tuition fees, among other things, to assist you in making an informed decision.
        </ContentParagraph>
        <ContentHeader>Step 3: Application Preparation</ContentHeader>
        <ContentParagraph>
        This is one of the most crucial steps. Once you've decided on your preferred course and university, the next step involves preparing your application. We provide step-by-step guidance on how to correctly fill out your application forms, write compelling personal statements, and prepare other essential documents. Our experts will review your application in detail to ensure it is error-free and presents your profile effectively.
        </ContentParagraph>
        <ContentHeader>Step 4: Submission and Follow-up</ContentHeader>
        <ContentParagraph>
        Upon completion of your application, we guide you through the submission process to ensure that all details are correctly and timely submitted.  But our work doesn’t stop there – we closely follow up with your chosen universities on the status of your application, keeping you informed at every stage of the process.
        </ContentParagraph>
        <ContentHeader>Step 5: Application Outcome</ContentHeader>
        <ContentParagraph>
        Once the university makes a decision on your application, we are here to help interpret the results. If your application is successful, we guide you on the next steps towards enrollment. In the event of a rejection, we analyze the reasons and provide recommendations for alternative courses or universities, or advise on improving your application for the next intake.
        </ContentParagraph>
        <ContentHeader>Step 6: Appeals and Reapplications</ContentHeader>
        <ContentParagraph>
        If required, we assist with appeals or reapplications to universities. We provide guidance on improving your application, addressing the reasons for rejection, and enhancing your appeal or reapplication to increase your chances of acceptance.
        </ContentParagraph>
        <ContentHeader>Step 7: Final Enrollment</ContentHeader>
        <ContentParagraph>
        Once your admission is secured, we guide you through the final enrollment process. This includes understanding and completing enrollment documentation, payment of tuition fees, and understanding your course schedule and commencement details.
        </ContentParagraph>
        <Conclusion>Please remember, our team is dedicated to ensuring a smooth, hassle-free application process for you, from the initial consultation to final enrollment.</Conclusion>
      </Content>
      <Contact>Contact Us</Contact>
      <ContactInformationPage includeAddress={false}/>
      </>
  )
}

export default Ap
