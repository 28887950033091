import React from "react";
import styled from "styled-components";

const BlogPostContainer = styled.div`
  max-width: 100%;
  box-sizing: border-box;
  margin: 0.5rem 1rem; 
  padding: 1rem; 
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PostImage = styled.img`
  width: 100%;
  height: auto;
`;

const PostTitle = styled.h2`
  font-size: 1.1rem;
  margin: 1rem 0;
`;

const PostDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const SeeMoreLink = styled.a`
  color: #007bff;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const BlogPost = ({ post }) => {
  const truncateDescription = (description, maxLength = 109) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <BlogPostContainer>
      <PostImage src={post.imagePath} alt={post.title} />
      <PostTitle>{post.title}</PostTitle>
      <PostDescription>
        {truncateDescription(post.description)}
      </PostDescription>
      <SeeMoreLink href={`/blog/${post._id}`}>See more</SeeMoreLink>
    </BlogPostContainer>
  );
};

export default BlogPost;

  