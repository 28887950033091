import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../components/Breadcrumbs';
import ContactInformationPage from './ContactInformationPage';

const Banner = styled.div`
  background-color: #4caf50;
  padding: 5rem;
  text-align: center;
  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.7em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Payment = () => {
    return (
      <>
        <Banner>
          <BannerTitle>Payment Services</BannerTitle>
          <Underline />
        </Banner>
        <Breadcrumbs/>
        <Content>
        <Main>Our Payment & Currency Exchange Services include:</Main>
          <ContentHeader>Step 1: Understanding Your Financial Needs</ContentHeader>
          <ContentParagraph>
          We start with understanding your financial needs and specific circumstances related to your international education fees. Our experts provide personalized advice to suit your requirements.
          </ContentParagraph>
          <ContentHeader>Step 2: Competitive Exchange Rates</ContentHeader>
          <ContentParagraph>
          We offer competitive exchange rates through our affiliated currency exchange company. This can lead to significant savings, especially for large amounts such as tuition fees, accommodation fees or living expenses.
          </ContentParagraph>
          <ContentHeader>Step 3: Secure Payment Solutions</ContentHeader>
          <ContentParagraph>
          We provide secure payment solutions for your international education fees. We ensure your funds reach the intended recipient safely and securely.
          </ContentParagraph>
          <ContentHeader>Step 4: Guidance on Cost-Effective Methods</ContentHeader>
          <ContentParagraph>
          We provide guidance on the most cost-effective methods to transfer funds internationally. Our aim is to help you save money and minimize fees associated with international transactions.
          </ContentParagraph>
          <ContentHeader>Step 5: Navigating International Transactions</ContentHeader>
          <ContentParagraph>
          International transactions can be complex. Our experts help you navigate these complexities, providing guidance at every step to ensure a smooth transaction process.
          </ContentParagraph>
          <Conclusion>By taking advantage of our currency exchange and payment services, you can maximize the value of your investment in your education abroad. We are committed to providing efficient, secure, and cost-effective financial solutions for our students.</Conclusion>
        </Content>
        <Contact>Contact Us</Contact>
        <ContactInformationPage includeAddress={false}/>
      </>
    )
  }
  
  
  export default Payment