import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import universities from "../data/australiauniversities";
import styled, { keyframes } from "styled-components";
import {
  ServicesContainer,
  ServicesGrid,
  ServiceCard,
  ServiceImageMobile,
  ServiceContentContainer,
  ServiceRightContainer,
  ServiceImage,
  ServiceLeftContainer,
  ServiceTitle,
  ServiceDescription,

} from "./ServicesPage";
import { InView } from "react-intersection-observer";
import CounsellingImage from '../images/Counseling.png';
import ApplicationImage from '../images/Application.png';
import VisaImage from '../images/visa.png';
import ScholarshipImage from '../images/Scholarship.png';
import bannerimage from '../images/tower.png';
import Breadcrumbs from "../components/Breadcrumbs";

const fadeUp = keyframes`
0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LearnMoreButton = styled.button`
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  background-color: #28A745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 0.5rem 0;
`;

const TitleContainer = styled.div`
  text-align: center;

`;

const PageTitle = styled.h2`
 
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 45%; 
  overflow: hidden;
  position: relative;
`;

const UniversityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    ${LearnMoreButton} {
      opacity: 1;
      visibility: visible;
      animation: ${fadeUp} 0.3s;
      cursor: pointer;
    }
  }
`;


const UniversityListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  margin: 0rem 5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0rem 0rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0rem 0rem;
  }
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }

    &.active {
      color: #007bff;
      font-weight: bold;
    }
  }
`;
const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background-image: url(${bannerimage});
  background-position: center;
  background-size: cover;
  

  @media (max-width: 768px) {

    background-position: center;
    background-size: cover;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;
  text-align:center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.div`
font-size: 1rem;
margin-bottom: 1rem;
color: #666;
text-align: justify;
line-height: 1.4rem;
margin-top: 1.5rem;

  @media screen and (min-width: 769px) {
    padding: 0 8rem; 
  }

  @media screen and (max-width: 1180px) {
    font-size: 0.9rem;
    padding: 0 2em; 
  }

 
`;



const Messagediv = styled.div`
color: #666;
line-height: 1.4rem;
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
  
  @media screen and (min-width: 769px) {
    padding: 0 2rem; 
  }
 
`;
const services = [

    {
      title: 'Renowned Education',
      description: 'Australia\'s educational institutions are globally recognized, offering a diverse range of courses and degrees. The Australian higher education system is known for its high standards and rigorous regulations, ensuring you\'ll receive the best quality education.',
      image: CounsellingImage,
    },
    {
      title: 'Work and Study',
      description: 'With the Australian Government allowing students to work part-time while studying, you can gain practical experience and manage your expenses better. This balance of work and study can be an enriching aspect of your student life in Australia.      ',
      image: ApplicationImage,
    },
    {
      title: 'Cultural Mix',
      description: 'Immerse yourself in the cultural diversity that Australia has to offer. From trying out exotic cuisines to participating in multicultural festivals, your Australian education journey can be a cultural adventure.      ',
      image: VisaImage,
    },
    {
      title: 'Innovation and Technology',
      description: 'Australia is known for its technological innovation and creative thinking. By studying in Australia, you\'ll be part of an environment that fosters innovation, creativity and independent thinking.',
      image: ScholarshipImage,
    },
  ];

const University = ({ university }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
        navigate(`/study-abroad/australia/${university.id}`);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClick();
      }
    };
  
    return (
        <UniversityContainer>
        <ImageWrapper>
          <img src={university.img} alt={university.name} />
        </ImageWrapper>
        <LearnMoreButton
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        >
          Learn more
        </LearnMoreButton>
      </UniversityContainer>
    );
  };
  const UniversityList = ({ universities, currentPage }) => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
  
    return (
      <UniversityListContainer>
        {universities.slice(startIndex, endIndex).map((university) => (
          <University key={university.id} university={university} />
        ))}
      </UniversityListContainer>
    );
  };
  
  const Pagination = ({ totalPages, currentPage, setCurrentPage }) => (
    <PaginationContainer>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          onClick={() => setCurrentPage(i + 1)}
          className={currentPage === i + 1 ? "active" : ""}
        >
          {i + 1}
        </button>
      ))}
    </PaginationContainer>
  );
  
const Studyinaustralia = () => {
    const [currentPage, setCurrentPage] = useState(1);
    return (
    <>
        <Banner>
        </Banner>
        <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
        <Title>Why Study In Australia?</Title>
      <Description>
        <p>
        Experience the unique Australian lifestyle while gaining world-class education! Australia's vibrant cities, high standard of living and multicultural society make it a top choice for international students.
        </p>
      </Description>
        <ServicesContainer>
        <ServicesGrid>
        {services.map((service, index) => (
  <InView triggerOnce threshold={0.1}>
    {({ inView, ref }) => {
      return (
        <ServiceCard
          ref={ref}
          key={service.title}
          index={index}
          className={inView ? "fade-in-up" : ""}
        >
          <ServiceImageMobile src={service.image} alt={service.title} />
          <ServiceContentContainer>
            {index % 2 === 0 ? (
              <>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
              </>
            ) : (
              <>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
              </>
            )}
          </ServiceContentContainer>
        </ServiceCard>
      );
    }}
  </InView>
))}
        </ServicesGrid>
      </ServicesContainer>
        <TitleContainer> 
          <PageTitle>Partnered Universities</PageTitle>
          <Messagediv>Most of the universities are applied via UCAS and other tech platforms</Messagediv>
        </TitleContainer>
        <UniversityList universities={universities} currentPage={currentPage} />
        <Pagination
          totalPages={Math.ceil(universities.length / 6)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };
  
  
export default Studyinaustralia