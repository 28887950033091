import styled, {keyframes} from 'styled-components';
import Address from '../components/address';
import { useState } from "react";
import axios from "axios"; 
import ReactSelect from "react-select";
import { useToast } from "@chakra-ui/react";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Loader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #28A745;
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${rotate} 2s linear infinite;
`;



const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5rem;
  border-radius: 5px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  text-align: justify;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #2D62B5;
  color: #fff;
  border-radius: 5px 5px 0 0;
  animation: ${fadeInLeft} 2s ease-in-out forwards;

  h2 {
    margin-bottom: 1rem;
  }
  p{
    line-height:1.5rem;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
    padding: 2rem;
    border-radius: 5px 0 0 5px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 768px) {
    border-radius: 0 5px 5px 0;
  }
`;


const FormField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
`;


const Label = styled.label`
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.7rem;
  
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    font-size: 1.1rem;
  }
`;

const TextArea = styled.textarea`
  padding: 0.7rem;
  font-size: 1rem;
  min-height: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    font-size: 1.1rem;
  }
`;
const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  padding: 0.7rem;
  font-size: 1rem;
  background-color: #2D62B5;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
`;

const Tick = styled.span`
  color: #28A745;
  font-size: 20px;
  font-weight: bold;
`;


const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
    borderColor: "#ccc",
    fontSize: "1rem",
    padding: "0.2rem",
    '@media screen and (max-width: 767px)': {
      fontSize: '1.1rem',
    },
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "1rem",
    '@media screen and (max-width: 767px)': {
      fontSize: '1.1rem',
    },
  }),
};

const ContactInformationPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    topic: "",
    place: "",
  });
  const toast = useToast();

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await axios.post("https://education-project-deploy-01c3d0549de6.herokuapp.com/send-email", formState);
      toast({
        title: "Success.",
        description: "Email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormState({ name: "", email: "", phone: "", message: "", topic: "", place: "", });
      setIsSent(true); 
      setTimeout(() => {
        setIsSent(false);
        setIsLoading(false);
      }, 5000);
    } catch (error) {
      console.error(error);
      toast({
        title: "An error occurred.",
        description: "Unable to send the email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <ContactContainer>
    <TitleContainer>
      <h2>Talk with Our Experts</h2>
      <p>
        Please fill out the form and one of our experts will get in touch with you shortly.
      </p>
    </TitleContainer>
    <ContactForm onSubmit={handleSubmit}>
  <FormField>
    <Label htmlFor="name">Name</Label>
    <Input placeholder='e.g., John Doe' type="text" id="name" value={formState.name} onChange={handleChange} required />
  </FormField>
  <FormField>
        <Label htmlFor="email">Email</Label>
        <Input placeholder='example@gmail.com' type="email" id="email" value={formState.email} onChange={handleChange} required />
</FormField>
<FormField>
<Label htmlFor="phone">Phone Number</Label>
<Input placeholder='+95-12345678' type="tel" id="phone" value={formState.phone} onChange={handleChange} />
</FormField>
<FormField>
  <Label htmlFor="topic">Your question is regarding?</Label>
  <ReactSelect
    id="topic"
    styles={customSelectStyles}
    placeholder="-Select-"
    value={formState.topic ? { value: formState.topic, label: formState.topic } : null}
    onChange={(option) => handleChange({ target: { id: "topic", value: option.value } })}
    options={[
      { value: "Universities/Colleges", label: "Universities/Colleges" },
      { value: "Scholarship", label: "Scholarship" },
      { value: "Visa", label: "Visa" },
      { value: "Course Info", label: "Courses" },
      { value: "Payment", label: "Payment" },
    ]}
  />
</FormField>
<FormField>
  <Label htmlFor="place">Preferred study destination</Label>
  <ReactSelect
    id="place"
    styles={customSelectStyles}
    placeholder="-Select-"
    value={formState.place ? { value: formState.place, label: formState.place } : null}
    onChange={(option) => handleChange({ target: { id: "place", value: option.value } })}
    options={[
      { value: "United Kingdom", label: "United Kingdom" },
      { value: "Australia", label: "Australia" },
      { value: "United States Of America", label: "United States Of America" },
      { value: "Canada", label: "Canada" },
      { value: "Singapore", label: "Singapore" },
      { value: "Thailand", label: "Thailand" },
    ]}
  />
</FormField>
<FormField>
<Label htmlFor="message">Message</Label>
<TextArea id="message" value={formState.message} onChange={handleChange} required />
</FormField>
<SubmitButton type="submit">
  {isLoading ? <Loader /> : isSent ? <Tick>&#10003;</Tick> : 'Submit'}
</SubmitButton>
</ContactForm>
    </ContactContainer>
    <Address/>
    </>
  )
}

export default ContactInformationPage;