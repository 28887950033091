export const menuData = [
  { title: 'Home', Link: '/' },
  {
    title: 'Study Abroad',
    Link: null,
    dropdown: [
      { title: 'Study in UK', Link: '/study-abroad/uk' },
      { title: 'Study in USA', Link: '/study-abroad/usa' },
      { title: 'Study in Canada', Link: '/study-abroad/canada' },
      { title: 'Study in Australia', Link: '/study-abroad/australia' },
      { title: 'Study in Singapore', Link: '/study-abroad/singapore' },
      { title: 'Study in Thailand', Link: '/study-abroad/thailand' },
    ],
  },
  {
    title: 'Our Services',
    Link: '/services',
    dropdown: [
      { title: 'Education Counseling', Link: '/Services/Education Counseling' },
      { title: 'Application Process', Link: '/Services/Application Process' },
      { title: 'Visa Guidance', Link: '/Services/Visa Application' },
      { title: 'Scholarship Guidance', Link: '/Services/Scholarship Application' },
      { title: 'Predeparture and Departure Guidance', Link: '/Services/Predeparture and Departure Guidance' },
      { title: 'Payment', Link: '/Services/Payment' },
    ],
  },
  {
    title: 'Company',
    Link: null,
    dropdown: [
      { title: 'About Us', Link: '/Company/About Us' },
      { title: 'News & Blog', Link: '/Company/News and Blog' },
      { title: 'Testimonials', Link: '/Company/Testimonials'},
      { title: 'Contact Us', Link: '/Company/Contact Information' },
      { title: 'Office Tour', Link: '/Company/Office Tour' },
    
    ],
  },
];

  