import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import universities from "../data/ukuniversities";
import styled, { keyframes } from "styled-components";
import {
  ServicesContainer,
  ServicesGrid,
  ServiceCard,
  ServiceImageMobile,
  ServiceContentContainer,
  ServiceRightContainer,
  ServiceImage,
  ServiceLeftContainer,
  ServiceTitle,
  ServiceDescription,

} from "./ServicesPage";
import { InView } from "react-intersection-observer";
import CounsellingImage from '../images/uk1.png';
import AccImage from '../images/uk2.png';
import bannerimage from '../images/uk1.jpg';
import VisaImage from '../images/uk3.png';
import ScImage from '../images/uk4.png';
import Breadcrumbs from "../components/Breadcrumbs";

const fadeUp = keyframes`
0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LearnMoreButton = styled.button`
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  background-color: #28A745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 0.5rem 0;
`;

const TitleContainer = styled.div`
  text-align: center;

`;

const PageTitle = styled.h2`
 
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 45%; // Adjust this value based on your desired aspect ratio
  overflow: hidden;
  position: relative;
`;

const UniversityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &:hover {
    ${LearnMoreButton} {
      opacity: 1;
      visibility: visible;
      animation: ${fadeUp} 0.3s;
      cursor: pointer;
    }
  }
`;


const UniversityListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  margin: 0rem 5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0rem 0rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0rem 0rem;
  }
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }

    &.active {
      color: #007bff;
      font-weight: bold;
    }
  }
`;
const Banner = styled.div`

  width: 100%;
  height: 500px;
  background-image: url(${bannerimage});
  background-size: contain;
  margin-top: 3rem;

  @media (max-width: 768px) {
    background-size: cover;
  }
`;

const Title = styled.h3`
color: #1466d9;
  font-size: 1.5rem;
  text-align:left;
  margin-top: 2rem;
  margin-left: 8rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-left: 2rem;
  }
`;

const Description = styled.div`
font-size: 1rem;
margin-bottom: 1rem;
color: #666;
text-align: justify;
line-height: 1.4rem;
margin-top: 1.5rem;

  @media screen and (min-width: 769px) {
    padding: 0 8rem; 
  }

  @media screen and (max-width: 1180px) {
    font-size: 0.9rem;
    padding: 0 2em; 
  }

  ul{
    list-style-position: inside;
     margin: 0;
    padding-top: 0.5rem;
    text-align: justify;
  }
`;



const Messagediv = styled.div`
color: #666;
line-height: 1.8rem;
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
  font-size: 1rem;
  
  @media screen and (max-width: 1180px) {
    padding: 0 2rem; 
    text-align: justify;
  }
 
`;
const services = [
  {
    title: 'UK Universities and Courses',
    description: 'The UK is home to renowned universities offering a variety of robust programs across numerous fields. Whether you\'re seeking undergraduate or postgraduate studies, our expert team can guide you through the selection process, helping you find a course and university that align with your ambitions.',
    image: CounsellingImage,
  },
  {
    title: 'Student Accommodation',
    description: 'Choosing the right student accommodation is key for a rewarding study experience in the UK. Our experts can guide you through the variety of options available, helping you secure housing that suits your preferences and budget.',
    image: AccImage,
  },
  {
    title: 'Student Visa Application',
    description: 'Navigating the visa application process for the UK can be challenging. Our team offers guidance on every step of the process, from document preparation to interview preparation, ensuring a smooth visa application journey.',
    image: VisaImage,
  },
  {
    title: 'Scholarship and Financial Aid',
    description: 'Numerous scholarships and financial aid options are available to help fund your UK education. We can assist you in identifying these opportunities and guide you through the application process, easing the financial burden of your studies.',
    image: ScImage,
  },
    
  ];

const University = ({ university }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
        navigate(`/study-abroad/uk/${university.id}`);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClick();
      }
    };
  
    return (
        <UniversityContainer>
        <ImageWrapper>
          <img src={university.img} alt={university.name} />
        </ImageWrapper>
        <LearnMoreButton
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        >
          Learn more
        </LearnMoreButton>
      </UniversityContainer>
    );
  };
  const UniversityList = ({ universities, currentPage }) => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
  
    return (
      <UniversityListContainer>
        {universities.slice(startIndex, endIndex).map((university) => (
          <University key={university.id} university={university} />
        ))}
      </UniversityListContainer>
    );
  };
  
  const Pagination = ({ totalPages, currentPage, setCurrentPage }) => (
    <PaginationContainer>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          onClick={() => setCurrentPage(i + 1)}
          className={currentPage === i + 1 ? "active" : ""}
        >
          {i + 1}
        </button>
      ))}
    </PaginationContainer>
  );
  
  const Studyinuk = () => {
    const [currentPage, setCurrentPage] = useState(1);
    return (
    <>
        <Banner>
        </Banner>
        <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
        <Title>Why Study In UK</Title>
      <Description>
        <p>
          The United Kingdom, a global hub of academic excellence and innovation, provides numerous opportunities for intellectual, personal, and professional growth. Whether you're considering undergraduate or postgraduate study, here's why you should consider the UK:
        </p>
        <ul>
          <li>
            Internationally Recognized Universitie
          </li>
          <li>
            Extensive Course Choices
          </li>
          <li>
            Quality Education at a Lower Cost
          </li>
          <li>
            Scholarships and Financial Aid
          </li>
          <li>
            Work While Studying
          </li>
        </ul>
      </Description>
        <ServicesContainer>
        <ServicesGrid>
        {services.map((service, index) => (
  <InView triggerOnce threshold={0.1}>
    {({ inView, ref }) => {
      return (
        <ServiceCard
          ref={ref}
          key={service.title}
          index={index}
          className={inView ? "fade-in-up" : ""}
        >
          <ServiceImageMobile src={service.image} alt={service.title} />
          <ServiceContentContainer>
            {index % 2 === 0 ? (
              <>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
              </>
            ) : (
              <>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
              </>
            )}
          </ServiceContentContainer>
        </ServiceCard>
      );
    }}
  </InView>
))}
        </ServicesGrid>
      </ServicesContainer>
        <TitleContainer> 
          <PageTitle>Partnered Universities</PageTitle>
          <Messagediv>
  The universities displayed below are our esteemed partners. However, we also facilitate applications to other universities through various technological platforms.
</Messagediv>

        </TitleContainer>
        <UniversityList universities={universities} currentPage={currentPage} />
        <Pagination
          totalPages={Math.ceil(universities.length / 6)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };
  
  
export default Studyinuk;


