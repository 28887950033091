import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styled, { keyframes, css } from 'styled-components';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Banner = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
  flex-direction: column;
  text-align: justify;
  opacity: ${props => props.show ? "1" : "0"};
  visibility: ${props => props.show ? "visible" : "hidden"};
  z-index: ${props => props.show ? "200" : "-1"};
  background-color: #2D62B5;
  color: white;
  font-size: 13px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  animation: ${props => props.show ? css`${fadeInUp} 2s ease-in-out forwards` : 'none'};
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    text-align: initial;
    align-items: flex-start;
    height: 200px;
    font-size: 16px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #2D62B5;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #f8f9fa;
  }
`;

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasConsented = Cookies.get('cookieConsent');
    if (hasConsented !== "true") {
      setTimeout(() => setShowBanner(true), 3000);  // 3 seconds delay
    }
  }, []);
  
  const onAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShowBanner(false);
  };

  return (
    <Banner show={showBanner}>
      <div>
        <h2  style={{ marginBottom: '0.5em' ,marginTop: '1em'}}>We use cookies <span role="img" aria-label="cookie">🍪</span></h2>
        <p style={{ marginBottom: '1em', lineHeight: '1.5em'}}>Our website uses cookies to understand how people use our website in order for us to improve our online experience.</p>
      </div>
      <Button id="accept-cookies" onClick={onAccept}>Accept</Button>
    </Banner>
  );
};

export default CookieBanner;
