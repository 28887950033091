import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import universities from "../data/ukuniversities";
import styled, { keyframes } from "styled-components";
import {
  ServicesContainer,
  ServicesGrid,
  ServiceCard,
  ServiceImageMobile,
  ServiceContentContainer,
  ServiceRightContainer,
  ServiceImage,
  ServiceLeftContainer,
  ServiceTitle,
  ServiceDescription,

} from "./ServicesPage";
import { InView } from "react-intersection-observer";
import CounsellingImage from '../images/Counseling.png';
import ApplicationImage from '../images/Application.png';
import VisaImage from '../images/visa.png';
import ScholarshipImage from '../images/Scholarship.png';
import bannerimage from '../images/tower.png';
import Breadcrumbs from "../components/Breadcrumbs";

const fadeUp = keyframes`
0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LearnMoreButton = styled.button`
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  background-color: #28A745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 0.5rem 0;
`;

const TitleContainer = styled.div`
  text-align: center;

`;

const PageTitle = styled.h2`
background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 45%; // Adjust this value based on your desired aspect ratio
  overflow: hidden;
  position: relative;
`;

const UniversityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    ${LearnMoreButton} {
      opacity: 1;
      visibility: visible;
      animation: ${fadeUp} 0.3s;
      cursor: pointer;
    }
  }
`;


const UniversityListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  margin: 0rem 5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0rem 0rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0rem 0rem;
  }
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }

    &.active {
      color: #007bff;
      font-weight: bold;
    }
  }
`;
const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background-image: url(${bannerimage});
  background-position: center;
  background-size: cover;


  @media (max-width: 768px) {

    background-position: center;
    background-size: cover;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;
  text-align:center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.div`
font-size: 1rem;
margin-bottom: 1rem;
color: #666;
text-align: justify;
line-height: 1.4rem;
margin-top: 1.5rem;

  @media screen and (min-width: 769px) {
    padding: 0 8rem; 
  }

  @media screen and (max-width: 1180px) {
    font-size: 0.9rem;
    padding: 0 2em; 
  }

  ul{
    list-style-position: inside;
     margin: 0;
    padding-top: 0.5rem;
    text-align: justify;
  }
`;



const Messagediv = styled.div`
color: #666;
line-height: 1.4rem;
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
  
  @media screen and (min-width: 769px) {
    padding: 0 2rem; 
  }
 
`;
const services = [

    {
      title: 'Academic Excellence',
      description: 'Singapore\'s universities are renowned for their high teaching standards and rigorous academic curriculum, often ranking among the top institutions worldwide. From Business and Finance, Engineering, to Life Sciences and Arts, Singapore offers a plethora of courses to cater to diverse academic interests.      ',
      image: CounsellingImage,
    },
    {
      title: 'Cultural Melting Pot',
      description: 'One of Singapore\'s unique attributes is its multicultural society, with various ethnic groups coexisting harmoniously. Studying in Singapore offers an enriching cultural exchange experience, where you can learn from different cultures and perspectives.',
      image: ApplicationImage,
    },
    {
      title: 'Strategic Location',
      description: 'Singapore\'s strategic location makes it a global hub for business and commerce. This gives students the opportunity for internships and employment opportunities with multinational corporations right in the heart of Asia.',
      image: VisaImage,
    },
    {
      title: 'Innovation and Research Opportunities',
      description: 'Singapore is a hotbed for innovation and research, with its government and universities heavily investing in research and development. This creates abundant opportunities for students to engage in cutting-edge research, work with renowned faculty, and gain hands-on experience in their field of study.',
      image: ScholarshipImage,
    },
  ];

const University = ({ university }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
        navigate(`/study-abroad/singapore/${university.id}`);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClick();
      }
    };
  
    return (
        <UniversityContainer>
        <ImageWrapper>
          <img src={university.img} alt={university.name} />
        </ImageWrapper>
        <LearnMoreButton
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        >
          Learn more
        </LearnMoreButton>
      </UniversityContainer>
    );
  };
  const UniversityList = ({ universities, currentPage }) => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
  
    return (
      <UniversityListContainer>
        {universities.slice(startIndex, endIndex).map((university) => (
          <University key={university.id} university={university} />
        ))}
      </UniversityListContainer>
    );
  };
  
  const Pagination = ({ totalPages, currentPage, setCurrentPage }) => (
    <PaginationContainer>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          onClick={() => setCurrentPage(i + 1)}
          className={currentPage === i + 1 ? "active" : ""}
        >
          {i + 1}
        </button>
      ))}
    </PaginationContainer>
  );
  

const Studyinsg = () => {
    const [currentPage, setCurrentPage] = useState(1);
    return (
    <>
        <Banner>
        </Banner>
        <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
        <Title>Why Study In Singapore?</Title>
      <Description>
        <p>
        Venture into the global world of education with Singapore, a thriving cosmopolitan city blending Eastern and Western cultures. Known for its competitive educational system, Singapore is an ideal destination for students seeking a top-notch education in a vibrant, multicultural environment.
        </p>
      </Description>
        <ServicesContainer>
        <ServicesGrid>
        {services.map((service, index) => (
  <InView triggerOnce threshold={0.1}>
    {({ inView, ref }) => {
      return (
        <ServiceCard
          ref={ref}
          key={service.title}
          index={index}
          className={inView ? "fade-in-up" : ""}
        >
          <ServiceImageMobile src={service.image} alt={service.title} />
          <ServiceContentContainer>
            {index % 2 === 0 ? (
              <>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
              </>
            ) : (
              <>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
              </>
            )}
          </ServiceContentContainer>
        </ServiceCard>
      );
    }}
  </InView>
))}
        </ServicesGrid>
      </ServicesContainer>
        <TitleContainer> 
          <PageTitle>Partnered Universities</PageTitle>
          <Messagediv>Most of the universities are applied via UCAS and other tech platforms</Messagediv>
        </TitleContainer>
        <UniversityList universities={universities} currentPage={currentPage} />
        <Pagination
          totalPages={Math.ceil(universities.length / 6)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };
  
    

export default Studyinsg