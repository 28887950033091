import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import universities from "../data/usuniversities";
import styled, { keyframes } from "styled-components";
import {
  ServicesContainer,
  ServicesGrid,
  ServiceCard,
  ServiceImageMobile,
  ServiceContentContainer,
  ServiceRightContainer,
  ServiceImage,
  ServiceLeftContainer,
  ServiceTitle,
  ServiceDescription,

} from "./ServicesPage";
import { InView } from "react-intersection-observer";
import eduimage from '../images/us2.png';
import diverseimage from '../images/us3.png';
import campusImage from '../images/us4.png';
import ScholarshipImage from '../images/Scholarship.png';
import bannerimage from '../images/tower.png';
import carImage from '../images/us5.png';
import Breadcrumbs from "../components/Breadcrumbs";

const fadeUp = keyframes`
0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LearnMoreButton = styled.button`
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  background-color: #28A745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 0.5rem 0;
`;

const TitleContainer = styled.div`
  text-align: center;

`;

const PageTitle = styled.h2`
 
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 45%; 
  overflow: hidden;
  position: relative;
`;

const UniversityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &:hover {
    ${LearnMoreButton} {
      opacity: 1;
      visibility: visible;
      animation: ${fadeUp} 0.3s;
      cursor: pointer;
    }
  }
`;


const UniversityListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  margin: 0rem 5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0rem 0rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 0rem 0rem;
  }
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }

    &.active {
      color: #007bff;
      font-weight: bold;
    }
  }
`;
const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background-image: url(${bannerimage});
  background-position: center;
  background-size: cover;


  @media (max-width: 768px) {

    background-position: center;
    background-size: cover;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
  text-align:center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.div`
font-size: 1rem;
margin-bottom: 1rem;
color: #666;
text-align: justify;
line-height: 1.4rem;
margin-top: 1.5rem;

  @media screen and (min-width: 769px) {
    padding: 0 8rem; 
  }

  @media screen and (max-width: 1180px) {
    font-size: 0.9rem;
    padding: 0 2em; 
  }

  ul{
    list-style-position: inside;
     margin: 0;
    padding-top: 0.5rem;
    text-align: justify;
  }
`;



const Messagediv = styled.div`
color: #666;
line-height: 1.8rem;
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
  font-size: 1rem;
  
  @media screen and (max-width: 1180px) {
    padding: 0 2rem; 
    text-align: justify;
  }
`;
const services = [

    {
      title: 'World-class Education',
      description: 'The U.S. is home to some of the world’s top universities and colleges. With a wide variety of courses and fields of study, students can pursue any path they can imagine. Whether it’s liberal arts, computer science, business, or medicine, American institutions provide unparalleled access to resources, research opportunities, and innovative teaching approaches.',
      image: eduimage,
    },
    {
      title: 'Diverse Culture',
      description: 'Studying in the U.S. provides an opportunity to learn and grow in a culturally diverse setting. You\'ll find a melting pot of cultures and traditions from all around the globe. This cultural exchange extends beyond the classroom, allowing students to gain unique insights and a global perspective.',
      image: diverseimage,
    },
    {
      title: 'Campus Life',
      description: 'American universities offer vibrant and active campus life, promoting interaction and learning outside the classroom. From joining clubs and organizations, participating in sports, to attending cultural events, there is always something to do and get involved with.      ',
      image: campusImage,
    },
    {
      title: 'Career Opportunities',
      description: 'The U.S. is the hub of innovation and home to numerous multinational companies and startups. With a degree from a U.S. institution, students are well positioned to pursue rewarding career opportunities both within the country and around the globe.',
      image: carImage,
    },
    {
      title: 'Financial Aid',
      description: 'Many universities offer scholarships, grants, and work-study opportunities to help offset the cost of education. We can help you navigate these options and identify the best financial aid packages to meet your needs.',
      image: ScholarshipImage,
    },
  ];

const University = ({ university }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
        navigate(`/study-abroad/usa/${university.id}`);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClick();
      }
    };
  
    return (
        <UniversityContainer>
        <ImageWrapper>
          <img src={university.img} alt={university.name} />
        </ImageWrapper>
        <LearnMoreButton
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        >
          Learn more
        </LearnMoreButton>
      </UniversityContainer>
    );
  };
  const UniversityList = ({ universities, currentPage }) => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
  
    return (
      <UniversityListContainer>
        {universities.slice(startIndex, endIndex).map((university) => (
          <University key={university.id} university={university} />
        ))}
      </UniversityListContainer>
    );
  };
  
  const Pagination = ({ totalPages, currentPage, setCurrentPage }) => (
    <PaginationContainer>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          onClick={() => setCurrentPage(i + 1)}
          className={currentPage === i + 1 ? "active" : ""}
        >
          {i + 1}
        </button>
      ))}
    </PaginationContainer>
  );
  
  const Studyinus = () => {
    const [currentPage, setCurrentPage] = useState(1);
    return (
    <>
        <Banner>
        </Banner>
        <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
        <Title>Why Study In USA?</Title>
      <Description>
        <p>
        United States, a place where your dreams become goals and your goals become achievements. Known as the land of opportunities, the U.S. offers an education experience that is rich, diverse, and committed to excellence.
        </p>
      </Description>
        <ServicesContainer>
        <ServicesGrid>
        {services.map((service, index) => (
  <InView triggerOnce threshold={0.1}>
    {({ inView, ref }) => {
      return (
        <ServiceCard
          ref={ref}
          key={service.title}
          index={index}
          className={inView ? "fade-in-up" : ""}
        >
          <ServiceImageMobile src={service.image} alt={service.title} />
          <ServiceContentContainer>
            {index % 2 === 0 ? (
              <>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
              </>
            ) : (
              <>
                <ServiceLeftContainer>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceLeftContainer>
                <ServiceRightContainer>
                  <ServiceImage src={service.image} alt={service.title} />
                </ServiceRightContainer>
              </>
            )}
          </ServiceContentContainer>
        </ServiceCard>
      );
    }}
  </InView>
))}
        </ServicesGrid>
      </ServicesContainer>
        <TitleContainer> 
          <PageTitle>Partnered Universities</PageTitle>
          <Messagediv>The universities and colleges displayed below are our esteemed partners. However, we also facilitate applications to other universities through various technological platforms.</Messagediv>
        </TitleContainer>
        <UniversityList universities={universities} currentPage={currentPage} />
        <Pagination
          totalPages={Math.ceil(universities.length / 6)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };
  
  
export default Studyinus;


