import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: auto; 
  min-height: 100vh;
  font-family: sans-serif;
}

`;

export default GlobalStyle;



