import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { TestimonialsContainer, TestimonialsGrid, TestimonialCard, StudentImage } from '../pages/Testimonialsection'
import Breadcrumbs from '../components/Breadcrumbs';

const BannerContainer = styled.div`
  background-color: #000;
  padding: 5rem 0;
  margin-top: 5rem;
`;

const BannerText = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const PageNumber = styled.button`
  background: none;
  border: none;
  color: #1a467a;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem;
  transition: 0.3s ease-in-out;
`;

const Ellipsis = styled.span`
  padding: 0.5rem;
`;

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const res = await axios.get('https://education-project-deploy-01c3d0549de6.herokuapp.com/api/testimonials');
        setTestimonials(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchTestimonials();
  }, []);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(testimonials.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = testimonials.slice(indexOfFirstItem, indexOfLastItem);

  const paginationItems = [];
  let ellipsisLeft = false;
  let ellipsisRight = false;

  for (let i = 1; i <= pages.length; i++) {
    if (
      i === currentPage ||
      i === 1 ||
      i === pages.length ||
      (i === currentPage - 1 && currentPage !== 2) ||
      (i === currentPage + 1 && currentPage !== pages.length - 1)
    ) {
      paginationItems.push(
        <PageNumber key={i} id={i} onClick={handleClick}>
          {i}
        </PageNumber>
      );
    } else if (i < currentPage && !ellipsisLeft) {
      paginationItems.push(<Ellipsis key={i}>...</Ellipsis>);
      ellipsisLeft = true;
    } else if (i > currentPage && !ellipsisRight) {
      paginationItems.push(<Ellipsis key={i}>...</Ellipsis>);
      ellipsisRight = true;
    }
  }

  return (
    <>
      <BannerContainer>
        <BannerText>Blog Post Details</BannerText>
      </BannerContainer>
      <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
      <TestimonialsContainer>
        <TestimonialsGrid>
        {currentItems.map((testimonial) => (
          <TestimonialCard key={testimonial.name}>
         <StudentImage src={testimonial.image} alt={testimonial.name} />
          </TestimonialCard>
        ))}
    </TestimonialsGrid>
      </TestimonialsContainer>
      <Pagination>{paginationItems}</Pagination>
    </>
  );
};

export default Testimonials;
