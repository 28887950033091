import Image1 from '../usunis/SMC.jpg';
import Image2 from '../usunis/occ.jpg';
import Image3 from '../usunis/CSM1.jpg';
import Image4 from '../usunis/Skyline.png';
import Image5 from '../usunis/Ecc.png';
import Image6 from '../usunis/Ohlone.jpg';
import Image7 from '../usunis/DeAnza.png';
import Image8 from '../usunis/greenriver.jpg';


const universities = [
  { id: 1,img: Image1, name: "Santa Monica College", description:"Santa Monica College is a public community college in Santa Monica, California. Founded as a junior college in 1929, SMC enrolls over 30,000 students in more than 90 fields of study.", summary:" Although initially serving primarily pre-college high school students, the college quickly expanded its enrollment to educate college-age students and non-traditional students with the primary intention to transfer to a four-year university The college has high transfer rates to four-year universities such as the Universities of California or California State Universities.", phone:" +1 310-434-4000", email:"Admissions@smc.edu", address:"CA 90405", coursesLink:"https://www.smc.edu/", },

  { id: 2,img: Image2, name: "Orange Coast College", description:"Orange Coast College is a public community college in Costa Mesa in Orange County, California. It was founded in 1947, with its first classes opening in the fall of 1948.", summary:"It provides Associate of Art and Associate of Science degrees, certificates of achievement, and lower-division classes transferable to other colleges and universities. The college enrolls approximately 24,000 undergraduate students. In terms of population size, Orange Coast College is the third-largest college in Orange County.", phone:"+1 714-432-5072", email:"arinfo@occ.cccd.edu ", address:"CA 92626", coursesLink:"https://orangecoastcollege.edu/", },

  { id: 3,img: Image3, name: "College Of San Mateo", description:"College of San Mateo is a public community college in San Mateo, California. It is part of the San Mateo County Community College District. College of San Mateo is located at the northern corridor of Silicon Valley and situated on a 153-acre site in the San Mateo hills", summary:"The college currently serves approximately 10,000 day, evening and weekend students. The college offers 79 A.A./A.S. degree majors, 75 certificate programs and approximately 100 transfer areas and majors.  ", phone:"+1 650-574-6161", email:"csmadmission@smccd.edu", address:"CA 94402", coursesLink:"https://collegeofsanmateo.edu/", },

  { id: 4,img: Image4, name: "Skyline College", description:"Skyline College is a public community college in San Bruno, California. It is one of three comprehensive community colleges in the San Mateo County Community College District. It was opened in 1969. Skyline College claims an annual population of over 17,000 students", summary:"With a full schedule of courses leading to over 100 associate degrees, certificates, and a bachelor's degree, Skyline College's academic programs aim to prepare students for transfer to a four-year university or to directly enter the workforce.", phone:"+1 650-738-4100", email:"skyinternational@smccd.edu", address:"CA 94066", coursesLink:"https://www.skylinecollege.edu/", },

  { id: 5,img: Image5, name: "El Camino College", description:"El Camino College is a public community college in Alondra Park, California. It consists of 37 buildings spanning an area of roughly 26 acres. It is one of two community colleges serving Southern California's South Bay area. ", summary:"El Camino College has a 2,000-seat auditorium, a 350-seat campus theatre, and the 190-seat Robert Hagg Recital Hall. The Marsee Auditorium is the venue for the South Bay Ballet's annual production of The Nutcracker, and is known for showcasing dance and opera companies, traveling artists, and other Broadway, film and television veterans, such as Shirley Jones and Gregory Hines. The Marsee Auditorium as well as the other on-campus venues also host El Camino College resident performers.", phone:"+1 310-532-3670", email:"admissionshelp@elcamino.edu", address:" CA 90506", coursesLink:"https://www.elcamino.edu/", },

  { id: 6,img: Image6, name: "Ohlone College", description:"Ohlone College is a public community college with its main campus in Fremont, California and a second campus in Newark. It is part of the California Community College System. The Ohlone Community College District serves the cities of Fremont and Newark, as well as parts of Union City.", summary:"Ohlone offers 61 associate degrees leading to university transfer or careers and over 100 vocational certificate programs that provide job skill training.", phone:"+1 510-659-6000", email:"admissions@ohlone.edu", address:"CA 94539", coursesLink:"https://www.ohlone.edu/", },

  { id: 7,img: Image7, name: "Foothill/De Anza College", description:"De Anza College is a public community college in Cupertino, California. It is part of the Foothill-De Anza Community College District, which also administers Foothill College in nearby Los Altos Hills, California. The college is named after the Spanish explorer Juan Bautista de Anza.", summary:"The school provides an internship program that gives students experience in working with computers. Qualified students in this program may get a chance to work in paid industry-based internships like in the Information Technology departments at Roche Pharmaceuticals, Synopsys, Fujitsu Computer Systems, Fujitsu America, Flextronics, Photon Dynamics and VMware. Students who are receiving financial aid are eligible to apply to receive a free refurbished computer. All donated computers are refurbished by interns in the program. ", phone:"+1 408-864-5300", email:"daintladmissions@deanza.edu ", address:"CA 95014", coursesLink:"https://www.deanza.edu/", },

  { id: 8,img: Image8, name: "Green River College", description:"Green River College is a public community college with its main campus in Auburn, Washington. It has a student body of approximately 10,000. The college primarily awards associates degrees but also offers 9 bachelor's degrees.", summary:"Green River College offers certificate, associate, and applied baccalaureate degree programs, as well as classes to prepare students for academic transfer to a four-year college or university; more than 40 professional and technical programs; and classes in Adult Basic Education and English for speakers of other languages. One of the college's signature programs is Aviation Technology, where students are trained for a variety of careers including Air Traffic Controller and Professional Pilot. Students learn in Green River's new state-of-the-art air traffic control lab and flight simulator. Top transfer programs include business, engineering and IT. Green River offers a wide variety of other majors in sciences, humanities and social sciences.", phone:"+1 253-833-9111", email:"International@greenriver.edu", address:" WA 98092", coursesLink:"https://www.greenriver.edu/", },


];

export default universities;
