import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import { IoPersonOutline } from "react-icons/io5";
const DropdownContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 250px;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  transition: 0.5s ease-in-out;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem;
`;

const DropdownMenu = styled.ul`
  padding: 0;
  margin: 0;
`;

const DropdownItem = styled.li`
  list-style: none;
  margin-bottom: 1.5rem;
 
`;

const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between; // Updated from 'flex-start' to 'space-between'
  color: #1466d9;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 100%; // Added this to allow the space-between to take effect

  &:hover {
    color: #2d62b5;
  }
`;

const ToggleIcon = styled.span`
  margin-left: 10px;
  display: inline-flex;
  transition: 0.4s ease-in-out;
  padding-left: 10px; 
  color: #000;
  font-size: 1rem;;
`;

const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Submenu = styled.div`
  max-height: ${({ expanded }) => (expanded ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-top: ${({ expanded }) => (expanded ? '1rem' : '0')};
`;


const NavBtn = styled.nav``;

const LoginButton = styled(Button)`
  background-color: #f3f3f3; 
  color: #FF9624;
  border-radius: 5px;
  font-weight: 600;
`;

const StyledIcon = styled(IoPersonOutline)`
  font-size: 24px; /* Adjust the size as needed */
  margin-right: 8px; /* Adds margin to the right of the icon */
  font-weight: 600;
`;

const ParentLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Dropdown = ({ isOpen, toggle, isLoading, setLoading }) => {
    const [expandedItems, setExpandedItems] = useState([]);
  
    const handleToggleClick = (e, index) => {
      e.stopPropagation();
      if (expandedItems.includes(index)) {
        setExpandedItems(expandedItems.filter((i) => i !== index));
      } else {
        setExpandedItems([...expandedItems, index]);
      }
    };
  
    const handleLinkClick = (e, item, index) => {
        if (!item.dropdown) {
          setLoading(true); 
          setTimeout(() => {
            setLoading(false); 
          }, 1000); 
          setExpandedItems([]); 
          toggle();
        } else {
          toggle();
        }
      };
      

    return (
       
        <DropdownContainer isOpen={isOpen}>
          <DropdownWrapper>
            <DropdownMenu>
              {menuData.map((item, index) => (
                <DropdownItem key={index}>
                  <ParentLink>
                    <DropdownLink
                      to={item.Link || '#'}
                      onClick={(e) => handleLinkClick(e, item, index)}
                    >
                      {item.title}
                    </DropdownLink>
                    {item.dropdown && (
                      <ToggleIcon
                        role="button"
                        tabIndex="0"
                        onClick={(e) => handleToggleClick(e, index)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleToggleClick(e, index);
                          }
                        }}
                      >
                        {expandedItems.includes(index) ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                      </ToggleIcon>
                    )}
                  </ParentLink>
                  <Submenu expanded={expandedItems.includes(index)}>
                    {item.dropdown &&
                      item.dropdown.map((branch, branchIndex) => (
                        <DropdownLink
                          to={branch.Link}
                          key={branchIndex}
                          style={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontSize: '0.9rem',
                          }}
                          onClick={(e) => handleLinkClick(e, branch, branchIndex)}
                        >
                          {branch.title}
                        </DropdownLink>
                      ))}
                  </Submenu>
                </DropdownItem>
              ))}
            </DropdownMenu>
            <BtnWrap>
            <NavBtn>
            <LoginButton to="https://app.safeneteducation.com" primary="true">
            <StyledIcon />   
            Login
          </LoginButton>
            </NavBtn>
            </BtnWrap>
          </DropdownWrapper>
        </DropdownContainer>
        
      );
    };
    
    export default Dropdown;
    