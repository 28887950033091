import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";

const BannerContainer = styled.div`
  background-color: #000;
  padding: 5rem 0;
  margin-top: 5rem;
  
`;

const BannerText = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
`;

const ContentContainer = styled.div`
  
  margin: 0 5rem auto;
  padding: 2rem;

  h3{
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.3rem;
  }
  p{
      text-align: justify;
      color: #333;
      font-size: 0.9rem;
  }

  @media (max-width: 1180px) {  
    margin: 0 0 auto;
    padding: 0rem 2rem;
  }
`;

const PostImage = styled.img`
  width: 100%;
  max-width: 800px;
  margin-left: -157px;
  object-fit: contain;
  
  max-height: 400px;

  @media (max-width: 1180px) {
    margin-left: 0;
    object-position: left;
  }
`;

const BlogPostDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(`https://education-project-deploy-01c3d0549de6.herokuapp.com/api/blogposts/${id}`)
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      });
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const paragraphs = post.description.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));

  return (
    <>
      <BannerContainer>
        <BannerText>Blog Post Details</BannerText>
      </BannerContainer>
      <Breadcrumbs/>
      <ContentContainer>
      <PostImage src={post.imagePath} alt={post.title} />
        <h3>{post.title}</h3>
        {paragraphs}
      </ContentContainer>
      </>
  );
};

export default BlogPostDetails;
