import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import WinWinAye from '../reviewImages/Student1.jpg'
import Student2 from '../reviewImages/Student2.jpg'
import Student3 from '../reviewImages/Student3.jpg'
import Student4 from '../reviewImages/Student4.jpg'
import Student5 from '../reviewImages/Student5.jpg'
import Student6 from '../reviewImages/Student6.jpg'

const SeeMoreButton = styled.button`
  display: block;
  background: none;
  border: none;
  text-align: center;
  font-size: 1rem;
  color: #2D62B5;
  cursor: pointer;
  margin: 1rem auto;
  transition: 0.3s ease-in-out;

  &:hover {
    color: #1a467a;
    text-decoration: underline; 
    text-underline-offset: 5px
  }
`;

const TestimonialsContainer = styled.div`
  margin: 3rem auto;
  width: 100%;
  padding: 0rem 5rem;

  @media screen and (max-width: 1024px) {
    padding: 0rem 0rem;
  }
`;

const TestimonialsHeader = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
`;



const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
 

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TestimonialCard = styled.div`
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: max-height 0.5s ease, opacity 0.5s ease;
`;


const StudentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;



const Testimonialsection = () => {

  let navigate = useNavigate();

  const handleSeeMoreClick = () => {
    navigate("/testimonials");
  };

  const testimonials = [
    { name: 'Win Win Aye', image: WinWinAye },
    { name: 'May Myat Noe Minn', image: Student2 },
    { name: 'Nang Su Yee', image: Student3 },
    { name: 'Myat Piti Cho', image: Student4 },
    { name: 'Aung Chit Phyo', image: Student5 },
    { name: 'Theingi Soe San', image: Student6 },
  ];

    return (
      <TestimonialsContainer>
        <InView triggerOnce threshold={0.1}>
          {({ inView, ref }) => (
            <TestimonialsHeader
              ref={ref}
              className={inView ? 'fade-in-left' : ''}
            >
              Students' Testimonials
            </TestimonialsHeader>
          )}
        </InView>
        <TestimonialsGrid>
          {testimonials.slice(0, 6).map((testimonial, index) => (
            <TestimonialCard key={testimonial.name}>
              <StudentImage src={testimonial.image} alt={testimonial.name} />
            </TestimonialCard>
          ))}
        </TestimonialsGrid>
        <SeeMoreButton onClick={handleSeeMoreClick}>
          Click here to see more!
        </SeeMoreButton>
      </TestimonialsContainer>
    );
  };
  export { TestimonialsContainer, TestimonialsHeader, TestimonialsGrid, TestimonialCard, StudentImage };
  export default Testimonialsection;