import React from 'react'
import styled, {keyframes} from 'styled-components'
import logo1 from '../images/logo.png'
import logo2 from '../images/SNU.png'
import { InView } from 'react-intersection-observer';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  max-width: 500px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  opacity: 0;
  animation: ${fadeInUp} 1s ease-in-out forwards;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  height: 100px;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyName = styled.h3`
  margin-bottom: 0.5rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
`;

const CompanyAddress = styled.p`
  margin-bottom: 0.5rem;
  
`;

const CompanyEmail = styled.a`
  margin-bottom: 0.5rem;
  display: block;
  text-decoration: none; 
  color: #2D62B5;
`;

const CompanyPhone = styled.a`
  margin-bottom: 0.5rem;
  display: block;
  text-decoration: none; 
  color: #2D62B5;
`;

const EmailLabel = styled.span`
  color: #000;
`;

const PhoneLabel = styled.span`
  color: #000;
`;



const OpeningTimes = styled.p`
  margin-bottom: 0.5rem;
`;



const Address = () => {
  return (
    <AddressContainer>
 <InView triggerOnce threshold={0.5}>
        {({ inView, ref }) => (
          <CompanyInfo ref={ref} style={{ animationPlayState: inView ? 'running' : 'paused' }}>
  <Logo src={logo1} alt="SafeNet Education" />
    <Info>
      <CompanyName>SafeNet Education </CompanyName>
      <CompanyAddress>No.1106, 11th Floor, Pearl Condo, Block C, Bahan Township, Yangon</CompanyAddress>
      <CompanyEmail href="mailto:safeneteducation@gmail.com">
            <EmailLabel>Email:</EmailLabel> safeneteducation@gmail.com
          </CompanyEmail>
          <CompanyPhone href="tel:+959967561128">
            <PhoneLabel>Phone:</PhoneLabel> +95 (9) 967561128
          </CompanyPhone>
      <OpeningTimes>Mon - Fri: 9am - 5pm</OpeningTimes>
    </Info>
  </CompanyInfo>
        )}
        </InView>
 <InView triggerOnce threshold={0.5}>
        {({ inView, ref }) => (
          <CompanyInfo ref={ref} style={{ animationPlayState: inView ? 'running' : 'paused' }}>
  <Logo src={logo2} alt="SafeNet Group" />
    <Info>
      <CompanyName>SafeNet Group</CompanyName>
      <CompanyAddress>Ground Floor, Pearl Condo, Block C, Bahan Township, Yangon</CompanyAddress>
      <CompanyEmail href="mailto:sales@safenetgroups.com">
            <EmailLabel>Email:</EmailLabel> sales@safenetgroups.com
          </CompanyEmail>
          <CompanyPhone href="tel:+9595136885">
            <PhoneLabel>Phone:</PhoneLabel> +95 (9) 5136885
          </CompanyPhone>
      <OpeningTimes>Mon - Fri: 9am - 5pm</OpeningTimes>
    </Info>
  </CompanyInfo>
        )}
        </InView>
</AddressContainer>
  );
};

export default Address;