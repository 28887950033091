import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../images/globe.png';
import PaymentImage from '../images/Currencysign.png';
import CounsellingImage from '../images/Counseling.png';
import ApplicationImage from '../images/Application.png';
import VisaImage from '../images/visa.png';
import ScholarshipImage from '../images/Scholarship.png';
import DepartureImage from '../images/Departure.png';
// import ScrollTrigger from 'react-scroll-trigger';
import './animations.css';
import { InView } from 'react-intersection-observer';
import Testimonialsection from './Testimonialsection';
import Breadcrumbs from '../components/Breadcrumbs';

const ServicesContainer = styled.div`
  margin: 2rem auto;
  width: 100%;


`;

const PageHeader = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  padding: 4rem 0;
  text-align: center;

`;

const PageTitle = styled.h1`
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const PageSubtitle = styled.h2`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
`;

const ServicesGrid = styled.div`
  display: flex;
  flex-direction: column;

`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: ${({ index }) => (index % 2 === 0 ? "#ffffff" : "#f7f7f7")};
  width: 100%;
`;


const ServiceImage = styled.img`
  max-width: 100%;
  width: 300px;
  height: auto;
  margin-bottom: 2rem;
`;

const ServiceTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;
const ServiceDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
  text-align: justify;
  line-height: 1.5rem;
  

  @media screen and (min-width: 769px) {
    padding: 0 2rem; 
  }

  @media screen and (max-width: 1180px) {
    font-size: 0.9rem;
  }
`;

const ServiceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
 

  @media screen and (min-width: 1180px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin: 0rem 4rem;
  }
`;

const ServiceRightContainer = styled.div`
  display: none;

  @media screen and (min-width: 1180px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ServiceImageMobile = styled.img`
  max-width: 100%;
  width: 300px;
  height: auto;
  margin-bottom: 2rem;

  @media screen and (min-width: 1180px) {
    display: none;
  }
`;



const ServiceLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1180px) {
    text-align: left;
  }
`;

const LearnMoreButton = styled.a`
background-color: #007bff;
border-radius: 5px;
color: #fff;
display: inline-block;
font-size: 0.9rem;
padding: 0.5rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
 

  &:hover {
    background-color: #fff;
    color: #007bff;
    border-width: 1px;
    border-style:solid;
  }
`;

const services = [
  {
    title: 'Education Counseling',
    description: 'Our team of experienced education counselors provide personalized guidance to help you choose the right course and university based on your interests, goals, and qualifications. We also advise on alternative pathways and recommend suitable institutions, ensuring you make an informed decision about your future studies.',
    link: '/Services/Education Counseling',
    image: CounsellingImage,
  },
  {
    title: 'Universities Application',
    description: 'Navigating the application process can be challenging, but our experts are here to help. We guide you through every step of the process, including document preparation, submission, and tracking. We also offer insights and advice on personal statements, essays, and interviews, ensuring you maximize your chances of success.',
    link: '/Services/Application Process',
    image: ApplicationImage,
  },
  {
    title: 'Visa Application ',
    description: 'Applying for a visa can be complex, but our comprehensive visa guidance ensures a smooth process. We provide advice on the necessary documentation and requirements, help you fill out the application forms accurately, and offer insights on common pitfalls and how to avoid them, giving you the confidence to navigate the visa application process.',
    link: '/Services/Visa Application',
    image: VisaImage,
  },
  {
    title: 'Scholarship Application',
    description: 'Funding your international education can be a challenge, but our team is here to help. We assist you in identifying and applying for scholarships, grants, and financial aid opportunities that are relevant to your chosen course and institution. Our guidance can help ease the financial burden of your studies and enable you to focus on achieving your academic goals.',
    link: '/Services/Scholarship Application',
    image: ScholarshipImage,
  },
  {
    title: 'Predeparture and Departure Guidance',
    description: 'Embarking on your international education journey can be both exciting and daunting. Our predeparture and departure guidance ensures you are fully prepared for your adventure. We provide advice on packing, accommodation, travel arrangements, and cultural adjustment, as well as tips for navigating any challenges you may encounter during your studies abroad.',
    link: '/Services/Predeparture and Departure Guidance',
    image: DepartureImage,
  },
  {
      title: 'Payment',
      description: 'Our currency exchange company offer competitive rates and secure payment solutions for your international education fees. We provide guidance on the most cost-effective methods to transfer funds and help you navigate the complexities of international transactions. By taking advantage of our currency exchange services, you can minimize fees and maximize the value of your investment in your education abroad.',
      link: '/services/Payment',
      image: PaymentImage,
},
];

const ServicesPage = () => {

  return (
    <ServicesContainer>
      <PageHeader>
        <PageTitle>Our Services</PageTitle>
        <PageSubtitle>Expert Guidance for Your International Education Journey</PageSubtitle>
      </PageHeader>
      <Breadcrumbs/>
      <ServicesGrid>
      {services.map((service, index) => (
          <InView triggerOnce threshold={0.1}>
            {({ inView, ref }) => (
              <ServiceCard
                ref={ref}
                key={service.title}
                index={index}
                className={inView ? 'fade-in-up' : ''}
              >
            <ServiceImageMobile src={service.image} alt={service.title} />
            <ServiceContentContainer>
              {index % 2 === 0 ? (
                <>
                  <ServiceRightContainer>
                    <ServiceImage src={service.image} alt={service.title} />
                  </ServiceRightContainer>
                  <ServiceLeftContainer>
                    <ServiceTitle>{service.title}</ServiceTitle>
                    <ServiceDescription>{service.description}</ServiceDescription>
                    <LearnMoreButton href={service.link}>Learn More</LearnMoreButton>
                  </ServiceLeftContainer>
                </>
              ) : (
                <>
                  <ServiceLeftContainer>
                    <ServiceTitle>{service.title}</ServiceTitle>
                    <ServiceDescription>{service.description}</ServiceDescription>
                    <LearnMoreButton href={service.link}>Learn More</LearnMoreButton>
                  </ServiceLeftContainer>
                  <ServiceRightContainer>
                    <ServiceImage src={service.image} alt={service.title} />
                  </ServiceRightContainer>
                </>
              )}
            </ServiceContentContainer>
          </ServiceCard>
           )}
           </InView>
        ))}
      </ServicesGrid>
      <Testimonialsection/>
    </ServicesContainer>
  );
              };  
              export { ServicesContainer, ServicesGrid, ServiceCard,   ServiceImageMobile, 
  ServiceContentContainer,
  ServiceRightContainer,
  ServiceImage,
  ServiceLeftContainer,
  ServiceTitle,
  ServiceDescription,
 };

export default ServicesPage;

