import Breadcrumbs from '../components/Breadcrumbs';
import React from 'react'
import styled from 'styled-components'
import ContactInformationPage from './ContactInformationPage';

const Banner = styled.div`
  background-color: #4caf50;
  padding: 5rem;
  text-align: center;
  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.7em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Ec = () => {
  return (
 <>
      <Banner>
        <BannerTitle>Education Counseling</BannerTitle>
        <Underline />
      </Banner>
      <Breadcrumbs/>
      <Content>
      <Main>Our Education Counseling Processes include:</Main>
        <ContentHeader>Step 1: Understanding Your Goals
</ContentHeader>
        <ContentParagraph>
        Our counseling process begins with a comprehensive discussion where we work to understand your educational and career aspirations, your skills, interests, and strengths. This conversation, which can take place either in-person at our office or virtually to suit your convenience, helps us to tailor our advice and recommendations to your unique needs and goals.
        </ContentParagraph>
        <ContentHeader>Step 2: Developing Your Academic Profile
</ContentHeader>
        <ContentParagraph>
        Once we understand your objectives, we help you build a detailed academic profile that highlights your past achievements, current skills, and future potential. This profile will serve as a foundation for exploring educational and career pathways that align with your goals.
        </ContentParagraph>
        <ContentHeader>Step 3: Exploration and Information</ContentHeader>
        <ContentParagraph>
        Armed with your profile, we assist in identifying suitable academic opportunities and career prospects. We provide detailed insights into prospective courses, universities, and industries that match your ambitions. Our counselors supply you with the most recent information regarding course requirements, curriculum structures, potential challenges, and specifics about your chosen academic field.
        </ContentParagraph>
        <ContentHeader>Step 4:  Decision Making and Planning</ContentHeader>
        <ContentParagraph>
        With the information at hand, we assist you in making informed decisions about your future. We work collaboratively to devise a personalized strategy that covers academic readiness, financial planning, and effective time management. We also frequently host webinars and conferences featuring industry experts, providing you with the opportunity to gain a wider perspective and valuable insights.
        </ContentParagraph>
        <ContentHeader>Step 5: Preparatory Sessions and University Interaction</ContentHeader>
        <ContentParagraph>
        To further help you make an informed choice, we conduct preparatory sessions which include interaction with university representatives. You'll have the opportunity to ask questions and gather firsthand knowledge about your chosen institutions.
        </ContentParagraph>
        <ContentHeader>Step 6: Counseling on Course Selection</ContentHeader>
        <ContentParagraph>
        Our counselors help you navigate the often overwhelming task of course selection. We provide details about the curriculum, faculty, facilities, student reviews, and other course-specific information to aid you in this process. Our goal is to ensure you make a decision that resonates with your career aspirations.
        </ContentParagraph>
        <ContentHeader>Step 7: Assistance with Financial Planning</ContentHeader>
        <ContentParagraph>
        We understand that financing your education is a significant consideration. Thus, our counseling also includes guidance on scholarships, financial aid options, cost of living in different countries, and ways to manage your finances while studying abroad.
        </ContentParagraph>
        <Conclusion>Throughout your journey, we are here to offer support, advice, and the expertise you need to make your educational aspirations a reality.</Conclusion>
      </Content>
      <Contact>Contact Us</Contact>
      <ContactInformationPage includeAddress={false}/>
      </>
  )
}

export default Ec
