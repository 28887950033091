import React, { useEffect } from "react";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import './animations.css';
import Breadcrumbs from "../components/Breadcrumbs";

const Container = styled.div`
  padding: 5rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    padding: 5rem 3rem;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  position: relative;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;

  #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Pagetitle = styled.h2`   
`;

const OfficeTour = () => {
    /* global google */
    useEffect(() => {
      const initMap = () => {
        const officeLocation = { lat: 16.81719497386486, lng: 96.15658186839151 };
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 15,
          center: officeLocation,
        });
        new google.maps.Marker({
          position: officeLocation,
          map: map,
        });
      };
    
      if (window.google) {
        initMap();
      } else {
        window.initMap = initMap;
      }
    }, []);
    
    return (
      <>
      <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
        <Container>
            <Pagetitle>Office Tour</Pagetitle>
        <InView triggerOnce threshold={0.1}>
          {({ inView, ref }) => (
            <VideoContainer
              ref={ref}
              className={inView ? "fade-in-up" : ""}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/4fB1YRNw8VE"
                title="Office Tour"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </VideoContainer>
          )}
        </InView>
        <h2>Find Our Office</h2>
        <InView triggerOnce threshold={0.1}>
          {({ inView, ref }) => (
            <MapContainer
              ref={ref}
              className={inView ? "fade-in-up" : ""}
            >
              <div id="map" style={{ width: "100%", height: "100%" }}></div>
            </MapContainer>
          )}
        </InView>
      </Container>
      </>
    );
  };
export default OfficeTour;



