import React, { useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
`;

const StyledForm = styled.form`
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  width: 300px;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 0.7rem 0;
  border-radius: 4px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

const NewImageUploadForm = () => {
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
  
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", selectedFile);
  
    try {
      await axios.post("https://education-project-deploy-01c3d0549de6.herokuapp.com/api/testimonials", formData);
     
      setName("");
      setSelectedFile(null);
    } catch (err) {
      console.error(err);
    
      if (err.response) {
        setErrorMessage(err.response.data.message || 'An error occurred.');
      } else {
  
        setErrorMessage('A network error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <InputGroup>
          <StyledLabel htmlFor="name">Name:</StyledLabel>
          <StyledInput id="name" value={name} onChange={handleNameChange} />
        </InputGroup>
        <InputGroup>
          <StyledLabel htmlFor="image">Image:</StyledLabel>
          <StyledInput id="image" type="file" onChange={handleFileChange} ref={fileInputRef} />
        </InputGroup>
        <StyledButton type="submit" disabled={isLoading}>
          {isLoading ? 'Uploading...' : 'Submit'}
        </StyledButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </StyledForm>
    </Container>
  );
};

export default NewImageUploadForm;





