import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import universities from '../data/sguniversities';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { BsFillTelephoneFill  } from "react-icons/bs";
import Breadcrumbs from '../components/Breadcrumbs';

const UniversityContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background: BLACK;
  background-position: center;
  background-size: cover;


`;

const UniversityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UniversityTitle = styled.h1`
  font-size: 2rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;

  @media (max-width: 768px){
      font-size: 1.5rem;
  } 
`;

const UniversityImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-bottom: 1rem;

`;

const BriefExplanation = styled.div`
  text-align: justify;
  line-height: 2rem;
  color: #666;

`;

const SummaryInformation = styled.div`
text-align: justify;
  h3 {
    
    margin-bottom: 0.5rem; 
    font-size: 1.5rem;
    background-image: linear-gradient(to right, #4caf50, #1e88e5);
    -webkit-background-clip: text;
    color: transparent;
  }
  p{
      line-height: 2rem;
      color: #666;
  }
`;

const ContactInformation = styled.div`
text-align: justify;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
`;

const CoursesLink = styled.a`
  display: inline-block;
  color: #218838;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const Sguniversitypage = () => {
    const { id } = useParams();
    const university = universities.find((uni) => uni.id === parseInt(id));
  
    if (!university) {
      return <div>University not found</div>;
    }
  
    return (
      <>
        <Banner>{university.name}</Banner>
        <Breadcrumbs displayNames={{ [id]: university.name }}/>
        <UniversityContainer>
        <UniversityDetails>
          <UniversityImage src={university.img} alt={university.name} />
          <UniversityTitle>{university.name}</UniversityTitle>
          <BriefExplanation>
            <p>{university.description}</p>
          </BriefExplanation>
          <SummaryInformation>
            <h3>Summary</h3>
            <p>{university.summary}</p>
          </SummaryInformation>
          <ContactInformation>
          <ContactItem>
           <BsFillTelephoneFill color="#4caf50"/>
            <span>Phone: {university.phone}</span>
          </ContactItem>
          <ContactItem>
            <FaEnvelope color="#4caf50"/>
            <span>Email: {university.email}</span>
          </ContactItem>
          <ContactItem>
            <FaMapMarkerAlt  color="#4caf50"/>
            <span>Postcode: {university.address}</span>
      </ContactItem>
      </ContactInformation>
      <CoursesLink href={university.coursesLink} target="_blank" rel="noopener noreferrer">
        Click here to view courses
      </CoursesLink>
    </UniversityDetails>
  </UniversityContainer>
  </>
    );
    };
export default Sguniversitypage