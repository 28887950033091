import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Breadcrumbs from "../components/Breadcrumbs";
import BlogPost from "./BlogPost";

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background: linear-gradient(135deg, rgba(76, 175, 80, 1) 0%, rgba(38, 198, 218, 1) 100%);
  background-position: center;
  background-size: cover;
`;

const BlogPostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 0rem 5rem;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0rem 0rem;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0rem 0rem;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #007bff;
  cursor: pointer;
  margin: 0 0.5rem;

  &:disabled {
    color: #6c757d;
    cursor: default;
  }
`;

const NewsBlogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allPosts, setAllPosts] = useState([]);
  const POSTS_PER_PAGE = 6;

  useEffect(() => {
    axios.get('https://education-project-deploy-01c3d0549de6.herokuapp.com/api/blogposts')
      .then((response) => {
        setAllPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  }, []);

  const getPaginatedPosts = (posts, currentPage) => {
    const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    return posts.slice(startIndex, endIndex);
  };

  const changePage = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  return (
    <>
      <Banner>Blog Posts</Banner>
      <Breadcrumbs nonNavigablePaths={['study-abroad', 'Company']} />
      <BlogPostsGrid>
        {getPaginatedPosts(allPosts, currentPage).map((post) => (
          <BlogPost key={post._id} post={post} />
        ))}
      </BlogPostsGrid>
      <Pagination>
        <PaginationButton
          onClick={() => changePage(-1)}
          disabled={currentPage === 1}
        >
          &laquo; Prev
        </PaginationButton>

        <PaginationButton
          onClick={() => changePage(1)}
          disabled={currentPage * POSTS_PER_PAGE >= allPosts.length}
        >
          Next &raquo;
        </PaginationButton>
      </Pagination>
    </>
  );
};

export default NewsBlogs;

