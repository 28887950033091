

const universities = [
  // { id: 1,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },

  // { id: 2,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },

  // { id: 3,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },

  // { id: 4,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },

  // { id: 5,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },

  // { id: 6,img: ImageOne, name: "", description:"", summary:"", phone:"", email:"", address:"", coursesLink:"", },
];

export default universities;
