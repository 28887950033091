import Breadcrumbs from '../components/Breadcrumbs';
import React from 'react'
import styled from 'styled-components'
import ContactInformationPage from './ContactInformationPage';

const Banner = styled.div`
  background-color: #4caf50;
  padding: 5rem;
  text-align: center;
  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.7em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Sg = () => {
    return (
      <>
        <Banner>
          <BannerTitle>Scholarship Guidance</BannerTitle>
          <Underline />
        </Banner>
        <Breadcrumbs/>
        <Content>
        <Main>Our Scholarship Guidance Process includes:</Main>
          <ContentHeader>Step 1: Understanding Your Goals</ContentHeader>
          <ContentParagraph>
          Our guidance process begins with a thorough conversation where we aim to understand your educational and career goals, your interests, and strengths. This helps us to personalize our advice and recommendations to your specific needs.
          </ContentParagraph>
          <ContentHeader>Step 2: Identifying Suitable Scholarships</ContentHeader>
          <ContentParagraph>
          Once we understand your goals, we assist you in identifying scholarships that align with your career objectives. We provide insights into various scholarships, their eligibility requirements, and application processes.
          </ContentParagraph>
          <ContentHeader>Step 3: Assisting with Application Documents</ContentHeader>
          <ContentParagraph>
          We help you prepare all necessary documents for your scholarship application. This includes help with writing impactful personal statements, resumes, and reference letters that stand out.
          </ContentParagraph>
          <ContentHeader>Step 4:  Application Submission</ContentHeader>
          <ContentParagraph>
          We ensure that your application documents are submitted on time and in the correct format. Our team stays updated on all deadlines to provide you with timely reminders.
          </ContentParagraph>
          <ContentHeader>Step 5: Interview Preparation</ContentHeader>
          <ContentParagraph>
          If your scholarship application includes an interview process, we provide mock interviews and constructive feedback to ensure you are well prepared.
          </ContentParagraph>
          <ContentHeader>Step 6: Managing Scholarship Awards</ContentHeader>
          <ContentParagraph>
          We guide you on how to manage scholarship awards and inform you about the terms and conditions associated with the scholarship.
          </ContentParagraph>
          <Conclusion>Throughout this journey, we provide you with support, advice, and the expertise you need to secure the scholarship that will help make your educational aspirations a reality.</Conclusion>
        </Content>
        <Contact>Contact Us</Contact>
        <ContactInformationPage includeAddress={false}/>
      </>
    )
  }
  
export default Sg