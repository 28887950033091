import React from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner'; // Change the import statement here

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const CustomLoader = () => {
  return (
    <LoaderContainer>
      <Oval 
        type="Oval"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} 
      />
    </LoaderContainer>
  );
};

export default CustomLoader;
