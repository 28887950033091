import ImageOne from '../ukunis/exeter.jpg'
import ImageTwo from '../ukunis/Sheffield.png'
import ImageThree from '../ukunis/chester.png'
import ImageFour from '../ukunis/Bristol.png'
import ImageFive from '../ukunis/Gloucestershire.webp'
import ImageSix from '../ukunis/Portsmouth.webp'
import ImageSeven from '../ukunis/Hertfordshire.png'
import ImageEight from '../ukunis/dmf.webp'
import ImageNine from '../ukunis/Wolverhampton.jpg'
import ImageTen from '../ukunis/uca.webp'
import ImageEleven from '../ukunis/coventry.png'
import ImageTwelve from '../ukunis/Plymouth-1.jpg'
import ImageThirteen from '../ukunis/bu.jpg'
import ImageFourteen from '../ukunis/ms.jpg'
import ImageFifteen from '../ukunis/city.png'
import ImageSixteen from '../ukunis/birmingham.png'
import ImageSeventeen from '../ukunis/wcg.webp'
import ImageEighteen from '../ukunis/ccg.png' 

const universities = [
  { id: 1,img: ImageOne, name: "University Of Exeter", description:"At the University of Exeter, we are using innovative and interdisciplinary research methodologies across Humanities and Social Sciences to understand, explore and address social inequalities that exist across communities.", summary:"The University of Exeter will give you the best head-start with your career through excellent teaching and a great learning environment. So what sets this university apart? Consistently in the top 10 in the UK, the University of Exeter is a member of the prestigious Russell Group, a group of research-intensive universities, and has been ranked 1st among this group of 24 universities for overall student satisfaction (The National Student Survey 2016). Plus, the University has a thriving international community and was ranked 1st in the UK for international student experience in WhatUni’s Student Choice Awards 2017.", phone:"+44 (0)1273 876 040 ", email:"ukes@intoglobal.com", address:" EX4 4QJ.", coursesLink:"https://www.exeter.ac.uk/" },
  
  { id: 2,img: ImageTwo, name:"University Of Sheffield", description:"We’re a world top-100 university. As a member of the Russell Group, our commitment to teaching excellence and world-class research will help you reach your true potential. We’ve shaped leading minds across the world, including six Nobel prize winners.", summary:"We’re a member of the Russell Group of leading UK research universities. Our outstanding performance for excellent teaching and research, as part of a genuinely global community, is consistently confirmed by international independent assessments.  ", phone:"+44 114 222 2000", email:"safeneteducation@gmail.com", address:"S10 2TN", coursesLink:"https://www.sheffield.ac.uk/"},
  
  { id: 3,img: ImageThree, name:"University Of Chester",description:"The University of Chester is a public university located in Chester, England. The university originated as the first purpose-built teacher training college in the UK.", summary:" The University of Chester is one of the UK's oldest higher education institutions with 180 years of teaching experience. The university has a diverse student community and is home to 20,000 students from 130 countries across the world.", phone:"01244 511000", email:"enquiries@chester.ac.uk", address:" CH1 2HT", coursesLink:"https://www1.chester.ac.uk/contact-us"},
  
  { id: 4,img: ImageFour, name:"University Of West England, Bristol", description:"The University of the West of England, Bristol, is a public university located in and around Bristol, United Kingdom.", summary:"UWE Bristol is a University Alliance university with a common mission to make the difference to our cities and regions. We use our experience of providing high quality teaching and research with real world impact to shape higher education and research policy for the benefit of our students and business and civic partners. We innovate together, learn from each other and support every member to transform lives and deliver growth. Our 14 different departments are spread across three campuses in and around Bristol – and we have campuses at Gloucester and Hartpury College, as well as offering courses through local study partners and global partner institutions. We have invested in the latest facilities and resources to give our students access to everything they need to succeed – with £300m being spent on new state-of-the-art learning spaces and accommodation as part of our 2020 Strategy to enhance our offer even further.", phone:"+44 (0)117 9656261", email:"infopoint@uwe.ac.uk", address:" BS16 1QY", coursesLink: "http://www.uwe.ac.uk"},
 
  { id: 5,img: ImageFive, name:"University Of Gloucestershire", description:"The University has grown into a diverse, vibrant community of 12,000 students and 1,600 staff with a strong teaching heritage stretching back nearly 200 years.", summary:"University of Gloucestershire is a diverse, vibrant community of 12,000 students and 1,500 staff with campuses in Cheltenham and Gloucester. Our teaching dates back to 1847; we’ve come a long way since then and have exciting plans for the future.", phone:"+44 (0)1242 714700", email:"communications@glos.ac.uk​", address:"GL50 2RH." , coursesLink: "https://www.glos.ac.uk/  "},
  
  { id: 6,img: ImageSix, name:"University Of Portsmouth", description:"The University of Portsmouth is a public university in the city of Portsmouth, England. The history of the university dates back to 1908, when the Park building opened as a Municipal college and public library.", summary:"We are one of only four universities in the south east to receive the highest ‘Gold’ rating in the Teaching Excellence Framework – a Government scheme that recognises excellent teaching in UK universities. The 37th best University in the UK, according to the Guardian University Guide 2018. Outranking the sector average for the 11th year in a row, we achieved an 88% satisfaction rate in the National Student Survey 2017. 19 of our courses achieved 100% satisfaction. We are one of the world’s top 100 new universities in the Times Higher Education Young University Rankings 2017. Our performance indicators show that 96.5% of our graduates are working or in further study within six months of graduation. The percentage of our graduates in professional/managerial level roles has increased by 3% to 75%, which means Portsmouth is above the sector average in the UK. Portsmouth graduates earn more than the sector average. Six months after completing an undergraduate course, a Portsmouth graduate’s average salary is £23,500. We have a population of more than 25,000 students and 2,500 full-time equivalent staff. We have more than 5,000 international and EU students from almost 150 different countries. 45% of our students are female and 55% are male.", phone:"+44 (0)23 9284 8484  ", email:"info.centre@port.ac.uk  ", address:"PO1 2UP" , coursesLink: "https://www.port.ac.uk/",},
  
  { id: 7,img: ImageSeven, name:"University Of Hertfordshire", description:"The University is the UK’s leading business-facing university and an exemplar in the sector. It is innovative and enterprising and challenges individuals and organisations to excel.", summary:"As a twenty-first century, business-facing university, we’re committed to giving our students the skills they need to succeed in the global marketplace.They leave the University of Hertfordshire confident, experienced and highly employable. In 2017, the University of Hertfordshire celebrates 25 years of being a university and 65 years of delivering first class higher education. Our history goes back to 1941 when Hertfordshire County Council entered in to partnership with the de Havilland Aircraft Company to train 15 and 16 year old aircraft apprentices. Then, in 1952, Hatfield Technical College was built by Hertfordshire County Council. We achieved university status in 1992 and are now focused on becoming internationally renowned as the UK’s leading business-facing university.", phone:"+44 (0)1707 284800  ", email:"ask@herts.ac.uk", address:"AL10 9AB", coursesLink: "http://www.herts.ac.uk",},
  
  { id: 8,img: ImageEight, name:"DemontFort University", description:"De Montfort University Leicester is a public university in the city of Leicester, England", summary:"With undergraduate students earning an average of £24,000 within six months of graduating at De Montfort University (DMU), the Leicester institution is one of the most valuable investments a student can make. Encouraging its students to develop their entrepreneurial flair, more than 100 new businesses are started by its own students each year, while Frontrunners – DMU’s campus-based placement scheme - allows current students to apply for a variety of work placements within the university, putting lecture theatre learning into practise at the workplace. By choosing DMU, you will be part of a creative and passionate student community that is inspired to make the most of the opportunities surrounding them. The university pride themselves on combining teaching excellence with world leading research and close links to businesses with over 170 courses accredited by professional bodies.", phone:"+44 (0)116 255 1551", email:"studentgateway@dmu.ac.uk.", address:"LE1 9BH", coursesLink: "https://www.dmu.ac.uk/study/courses/courses.aspx"},
  
  { id: 9,img: ImageNine, name:"University Of Wolverhampton", description:"The University of Wolverhampton is a public university located on four campuses across the West Midlands, Shropshire and Staffordshire in England.", summary:"At the University of Wolverhampton, our courses unlock your potential and help you on your way to a bright future.", phone:"+44 (0) 1902 323 505", email:" admissions@wlv.ac.uk", address:" WV1 1LY", coursesLink: "https://www.wlv.ac.uk/apply/time-to-apply/?utm_source=Google&utm_medium=Paid_Search&utm_campaign=UG-2023&gad=1&gclid=Cj0KCQjw3a2iBhCFARIsAD4jQB2NroZBQcneS4ytzS7wEarTuRisFwQ6Qiw_Otc6k0Ksf22-dzGvTy0aArDsEALw_wcB&gclsrc=aw.ds"},
  
  { id: 10,img: ImageTen, name:"University for the Creative Arts", description:"The University for the Creative Arts is a specialist art and design university in the south of England", summary:"At UCA, we believe that creativity makes global communities more vibrant, more innovative, more human. Which is why, for 160 years, our university has been a passionate advocate for creative education and research. We offer courses in Architecture, Crafts, Fashion, Graphic Design, Illustration, Fine Art, Photography, Film, Media, the Performing Arts and Creative Business. Our programmes are acclaimed for their top teaching quality, and highly respected by creative sector employers.", phone:"+44 (0)1252 416110", email:"admissions@uca.ac.uk ", address: "GU9 7DS", coursesLink:"https://www.uca.ac.uk/study/courses/",},
  
  { id: 11,img: ImageEleven, name:"Coventry University", description:"Coventry University is a public research university in Coventry, England. The origins of Coventry University can be linked to the founding of the Coventry School of Design in 1843.", summary:"We are a forward-looking, modern university with a proud tradition as a provider of high quality education and a focus on applied research. Our students benefit from state-of-the-art equipment and facilities in all academic disciplines including health, design and engineering laboratories, performing arts studios and computing centres. We have been chosen to host three national Centres of Excellence in Teaching and Learning which has enabled us to invest substantial sums of money in health, design and mathematics. Our city-centre campus is continually developing and evolving, and we have plans for further investment in it over the next few years. We are a major presence in Coventry, which contributes to the city's friendly and vibrant atmosphere and also enables us to foster successful business partnerships. Through our links with leading edge businesses and organisations in the public and voluntary sectors, our students are able to access project and placement opportunities that enhance their employability on graduation.", phone:"024 7688 7688", email:"ukadmissions@coventry.ac.uk", address:"CV1 5FB", coursesLink: "https://www.coventry.ac.uk/"},

  { id: 12,img: ImageTwelve, name:"University Of Plymouth", description:"The University of Plymouth is a public research university based predominantly in Plymouth, England, where the main campus is located, but the university has campuses and affiliated colleges across South West England. With 18,410 students, it is the 57th largest in the United Kingdom by total number of students. ", summary:"The University of Plymouth is a top 5 UK Modern University located in the South West, nestled between the beaches of Devon and Cornwall and picturesque Dartmoor. In this beautiful setting, the university seeks to advance knowledge and transform lives through its broad range of subjects.  ", phone:"01752 585858", email:"admissions@plymouth.ac.uk", address:"PL4 8AA", coursesLink:"https://www.plymouth.ac.uk/study/clearing?utm_source=google&utm_medium=cpc&utm_campaign=clearing-phase2-23-rh&gclid=Cj0KCQjw_O2lBhCFARIsAB0E8B92HVdoFF_u65c4r_jHTK4BNuwNVtvUxF3_H-phJDHinjWiQQM6Y90aAowyEALw_wcB"},

  { id: 13,img: ImageThirteen, name:"University Of Bournemouth", description:"Bournemouth University is a public university in Bournemouth, England, with its main campus situated in neighbouring Poole. The university was founded in 1992; however, the origins of its predecessor date back to the early 1900s", summary:"The university currently has over 16,000 students, including over 3,000 international students. The university is recognised for its work in the media industries. Graduates from the university have worked on a number of Hollywood films, including Gravity, which was awarded the Achievement in Visual Effects Oscar at the 86th Academy Awards in 2015", phone:"+44 (0) 1202961916", email:"enquiries@bournemouth.ac.uk", address:"BH12 5BB", coursesLink: "https://www.bournemouth.ac.uk/study/undergraduate/clearing?utm_source=google&utm_medium=cpc&utm_campaign=UG2023_Clearing&utm_id=BOU007&utm_content=phase2_class_brand&gad=1&gclid=Cj0KCQjw_O2lBhCFARIsAB0E8B8kVa7GMiqtIK4a4-WmgWXBYjX7fEMJifPZyLmi32rW5Cn91XMDeN0aAgwAEALw_wcB"},

  { id: 14,img: ImageFourteen, name:"Middlesex University", description:"Middlesex University London is a public research university in Hendon, northwest London, England. The name of the university is taken from its location within the historic county boundaries of Middlesex.", summary:"Middlesex has a student body of over 19,000 in London and over 37,000 globally. The university has student exchange links with over 100 universities in 22 countries across Europe, the United States, and the world. More than 140 nationalities are represented at Middlesex's Hendon campus alone.[5] Additionally, it has campuses in Malta, Dubai and Mauritius as well as a number of local offices across the globe. Courses are delivered by the Faculty of Science and Technology, Faculty of Professional and Social Sciences, and the Faculty of the Arts and Creative Industries.", phone:"020 8411 5000", email:"EducationLiaison@mdx.ac.uk ", address:" NW4 4BT", coursesLink: "https://www.mdx.ac.uk/"},

  { id: 15,img: ImageFifteen, name:"City, University Of London", description:"City, University of London, is a public research university in London, United Kingdom, and a member institution of the federal University of London. It was founded in 1894 as the Northampton Institute, and became a university when The City University was created by royal charter in 1966.", summary:"The university has its main campus in Central London in the London Borough of Islington, with additional campuses in Islington, the City of London, the West End and East End. It is organised into six schools, within which there are around forty academic departments and centres,[8] including the Department of Journalism, Bayes Business School (formerly Cass Business School), and City Law School which incorporates the Inns of Court School of Law.", phone:"020 7040 5060", email:"safeneteducation@gmail.com", address:" EC1V 0HB", coursesLink:"https://www.city.ac.uk/"},

  { id: 16,img: ImageSixteen, name:"University Of Birmingham", description:"The University of Birmingham (informally Birmingham University)[8][9] is a public research university in Birmingham, England. It received its royal charter in 1900 as a successor to Queen's College, Birmingham (founded in 1825 as the Birmingham School of Medicine and Surgery), and Mason Science College (established in 1875 by Sir Josiah Mason), making it the first English civic or 'red brick' university to receive its own royal charter, and the first English unitary university", summary:"University of Birmingham courses will set you up for a rich and fulfilling career and are renowned for producing highly employable and successful graduates. In 2020-21, they are ranked first by High Fliers Research, as the most targeted UK university for the country’s top employers looking for graduate recruits (The Graduate Market in 2021), demonstrating their commitment to you and your future. The University has also invested £5m in its graduate careers services and successful alumni are offered mentoring and bursaries to support work experience and internships in the UK and overseas. International students are offered a variety of specially tailored events, including coaching and skills workshops on topics such as networking skills, job-seeking strategies and job seeking beyond the UK.", phone:"+44 (0)121 414 3344", email:"onlineadmissions@contacts.bham.ac.uk.", address:"B15 2TT", coursesLink: "https://www.birmingham.ac.uk/index.aspx"},

  { id: 17,img: ImageSeventeen, name:"Warwickshire College", description:"WCG is the managing body that administers several colleges of further education in the English West Midlands, namely in the counties of Warwickshire and Worcestershire.", summary:"WCG is a group of colleges that, combined, make up one of the most successful further and higher education colleges in the UK. Each individual college has its own unique identity which means that together we can deliver a broad range of study programmes to help develop the wider skills bases on which our local economies depend. Additionally, our variety of facilities which are open to the public allows us to serve our local communities and give our students more opportunities to be enriched by their college experience.", phone:"0300 456 0047", email:"customerservices@wcg.ac.uk", address:"CV32 5JE", coursesLink: "https://wcg.ac.uk/"},

  { id: 18,img: ImageEighteen, name:"Chichester College", description:"Chichester College is a college of further education in Chichester, West Sussex, England. It has a second campus at Brinsbury, near Pulborough. It is a member of the Collab Group which represents the largest colleges in England.", summary:"Chichester College has over 20,000 students, of whom over 5,000 are full-time (about 4,000 are between 16 and 18 years old; 1,000 are over 19 and several hundred are taking university level courses). Students can choose from a wide range of courses including: A-levels (about 900 students); BTEC Diplomas at Level 3 (vocational A-level equivalents); and many vocational qualifications that prepare young people and adults for working life.", phone:"01243 786321", email:" info@chichester.ac.uk", address:"PO19 1SB", coursesLink: "https://www.chichester.ac.uk/"},

];

export default universities;
