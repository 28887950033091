import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../components/Breadcrumbs';
import ContactInformationPage from './ContactInformationPage';

const Banner = styled.div`
  background-color: #4caf50;
  padding: 5rem;
  text-align: center;
  margin-top: 5rem;
`;

const BannerTitle = styled.p`
  color: #fff;
  font-size: 2em;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const Underline = styled.div`
  width: 20%; 
  border-bottom: 2px solid #f5f5f5; 
  margin: 20px auto; 

  @media (max-width: 768px) {
    width: 50%; 
  }

  `;


const Content = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const ContentHeader = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Main = styled.p`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: 1.7em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Conclusion = styled.p`
font-size: 1em;
line-height: 1.5rem;
margin-bottom: 20px;
text-align: justify;
color: #666;
@media (max-width: 768px) {
  font-size: 0.9em;
}
`;

const ContentParagraph = styled.p`
  font-size: 1em;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 20px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Contact = styled.h3`
background: -webkit-linear-gradient(left, #4caf50, #1e88e5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 2em;
margin-bottom: -3em;
font-size: 2em;
text-align: center;

@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

const Pd = () => {
    return (
      <>
        <Banner>
          <BannerTitle>Pre-Departure & Departure Guidance</BannerTitle>
          <Underline />
        </Banner>
        <Breadcrumbs/>
        <Content>
        <Main>Our Pre-Departure & Departure Guidance includes:</Main>
          <ContentHeader>Step 1: Pre-Departure Briefing</ContentHeader>
          <ContentParagraph>
          We conduct comprehensive pre-departure sessions covering various aspects such as cultural sensitivity, health and safety, travel planning, accommodation and student life in the new country.
          </ContentParagraph>
          <ContentHeader>Step 2: Document Checklist</ContentHeader>
          <ContentParagraph>
          We assist you in preparing a checklist of all important documents you will need for your journey and stay abroad. This ensures you are well-prepared and not missing any essential documents.
          </ContentParagraph>
          <ContentHeader>Step 3: Accommodation Assistance</ContentHeader>
          <ContentParagraph>
          We help you with the process of finding and securing comfortable and safe accommodation that fits within your budget and is close to your university.
          </ContentParagraph>
          <ContentHeader>Step 4:  Travel Arrangements</ContentHeader>
          <ContentParagraph>
          As part of our services, we also offer travel assistance. We have a dedicated ticketing service that helps you find the most convenient and cost-effective travel options.
          </ContentParagraph>
          <ContentHeader>Step 5: Local Transportation</ContentHeader>
          <ContentParagraph>
          We guide you on the local transportation system of the city you'll be moving to, including information on public transport, taxi services, and car rentals.
          </ContentParagraph>
          <ContentHeader>Step 6: Stay Connected</ContentHeader>
          <ContentParagraph>
          We help you understand the process of getting a local SIM card and opening a bank account in your new city. These are essential to stay connected and manage your finances abroad.
          </ContentParagraph>
          <Conclusion>Our team is here to ensure that your transition to your new life abroad is as smooth and worry-free as possible. We remain available to support you even after your departure.</Conclusion>
        </Content>
        <Contact>Contact Us</Contact>
        <ContactInformationPage includeAddress={false}/>
      </>
    )
  }
  
  export default Pd