import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Create styled components for each HTML element
const BreadcrumbNav = styled.div`
  background: #f8f9fa;
  padding: 0.75rem 6rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: 1180px){
    padding: 0.75rem 3rem;
  }
  @media screen and (max-width: 768px){
    padding: 0.75rem 1.3rem;
    line-height: 2;
  }
`;

const NonNavigableBreadcrumb = styled.span`
background-image: linear-gradient(to right, #4caf50, #1e88e5);
-webkit-background-clip: text;
color: transparent;
`;


const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: grey;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1rem;

`;

const Separator = styled.span`
  margin: 0.5rem;
  background-image: linear-gradient(to right, #4caf50, #1e88e5);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1rem;
`;

const Breadcrumbs = ({ displayNames = {}, nonNavigablePaths = [] }) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
  
    return (
      <BreadcrumbNav>
        <BreadcrumbLink to="/">Home</BreadcrumbLink>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const displayName = displayNames[value] || decodeURIComponent(value);

          return nonNavigablePaths.includes(value) ? (
            <span key={to}>
              <Separator>{'/'}</Separator>
              <NonNavigableBreadcrumb>{displayName}</NonNavigableBreadcrumb>
            </span>
          ) : (
            <span key={to}>
              <Separator>{'/'}</Separator>
              <BreadcrumbLink to={to}>{displayName}</BreadcrumbLink>
            </span>
          );
        })}
      </BreadcrumbNav>
    );
  };

  
  export default Breadcrumbs;
  