import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundIcon from '../images/404.png'; // Import your 404 icon

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img src={NotFoundIcon} alt="Page Not Found" style={styles.icon} />
        <p style={styles.description}>We can't seem to find the page you're looking for.</p>
        <Link to="/" style={styles.homeLink}>Go back home</Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '72vh',
    padding: '0 20px'
  },
  content: {
    maxWidth: '600px', // Adjust as needed
  },
  icon: {
    width: '300px', // Adjust as needed
    height: 'auto'
  },
  description: {
    fontSize: '1em',
    color: '#666',
    marginTop: '20px'
  },
  homeLink: {
    display: 'inline-block',
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px'
  }
};

export default NotFoundPage;
